import React from 'react';
import { ThemeProvider } from '@mui/material';
import { lightTheme } from '../../../themes';

const MicrositeLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <ThemeProvider theme={lightTheme}>
      <div style={{ width: '100vw' }}>
        {children}
      </div>
    </ThemeProvider>
  );
};

export default MicrositeLayout