import styled from "styled-components";
import { Column, Row } from "../_shared/styledComponents";
import NoResults from "../_shared/components/NoResults";
import EventCardNew from "./css/EventCard";
import { EventItem } from "./_helpers/types";
import { HeaderGreenTopBar, HeaderText } from "../_shared/styled";

type Props = {
  header: string;
  events: Array<EventItem>;
  type?: string;
  showStartPrice?: boolean;
};

const EventsListComponent = (props: Props) => {
  const { events, header, type, showStartPrice } = props;

  return (
    <EventsListComponentWrapper>
      <HeaderGreenTopBar />
      <HeaderText>{header}</HeaderText>

      <Column spacing={13} style={{ marginTop: 5 }}>
        {events.length < 1 && (
          <Row style={{width:"100%", height:"200px"}} mainAxisAlignment="center">
            <div style={{marginTop:"40px"}}>
            <NoResults
            header={"Nothing Here!"}
            body={"There are no featured events here at the moment"}
            
          />
            </div>
          </Row>
        )}

        <EventsContainer>
          {events.map((event, index) => (
            <EventCardContainer key={index}>
              <EventCardNew event={event} type={type} showStartPrice={showStartPrice} />
            </EventCardContainer>
          ))}
        </EventsContainer>
      </Column>
    </EventsListComponentWrapper>
  );
};

export default EventsListComponent;

const EventsListComponentWrapper = styled.div`
width:100%; margin-top:2rem;`;



const EventsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4,1fr);
  gap: 15px;
  margin-top: 20px;
  width: 100%;
  @media screen and (max-width: 1300px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 950px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const EventCardContainer = styled.div``;
