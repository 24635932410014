// Footer.tsx
import React from 'react';
import './css/footer.css';

const MicroFooter = () => {
  return (
    <footer className="footer">
      <ul className="nav">
        <li className="nav-item">
          <a href="/i" className="nav-link">Home</a>
        </li>
        <li className="nav-item">
          <a href="https://events.qasa.me/" className="nav-link">Events</a>
        </li>
        <li className="nav-item">
          <a href="/i/privacy-policy" className="nav-link">Privacy Policy</a>
        </li>
        <li className="nav-item">
          <a href="/i/terms-of-service" className="nav-link">Terms of service</a>
        </li>
        <li className="nav-item">
          <a href="/i/downloads" className="nav-link">Downloads</a>
        </li>
        <li className="nav-item">
          <a href="/i/contact" className="nav-link">Help</a>
        </li>
        <li className="nav-item">
          <a href="https://mangofundgroup.com/" className="nav-link">Funding</a>
        </li>
        <li className="nav-item">
          <a href="/i/faq" className="nav-link">FAQs</a>
        </li>
      </ul>
      <p className="copyright-text">&copy; 2024 <a href="https://dubtel.com">dubtel</a></p>
    </footer>
  );
};

export default MicroFooter;