import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../EventDetails/css/eventDetails.css";
import { apiGetRequest, apiPostRequest, apiPutRequest } from "../_shared/api";
import { HeaderGreenTopBar } from "../_shared/styled";

import { ContentTypeEnum } from "../_shared/enum/content-type.enum";
import { loadStripe } from "@stripe/stripe-js";
import { baseReceiptUrl, errorMessage, urls } from "../_shared/constants";
import VaryingWidthImage from "../_shared/components/imageViewer";
import { EventRegister } from "react-native-event-listeners";
import { EventsEnum } from "../_shared/types";
import { toast } from "react-toastify";
import { StyledTextField } from "../Auth/AuthPage";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Modal,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Column, FullWidthColumn, Row } from "../_shared/styledComponents";
import { CheckOutOptions } from "./checkoutOptions";
import { ErrorSeverityEnum } from "../_shared/enum/error-severity.enum";
import {
  TicketInfo,
  UserWallet,
  Ticket,
  EventData,
  CustomFields,
  PurchasedTicket,
} from "./interfaces";
import CloseIcon from "@mui/icons-material/Close";
import { DisplayStatusEnum } from "../Home/_helpers/types";
import MetaTags from "../_shared/components/helmets";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "./CheckoutForm";
import { convertDateTimeToUsersTimezone } from "../_shared/services";

function EventCheckout() {
  const location = useLocation();
  const {
    purchasedTickets,
    eventDetailz,
    ticket_subTotal,
    ticket_currency,
    ticket_fees,
    user_phone,
    user_country,
    user_wallet,
    isPromoCodeUsed,
    tickets_data,
    ticket_promo_code,
    user_data,
  } = location.state || {};
  const [eventDetails, setEventDetails] = useState<EventData>(eventDetailz);
  const [purchasedTicket, setPurchasedTicket] = useState<
    Array<PurchasedTicket>
  >([purchasedTickets]);

  const [phone, setUserPhone] = useState(user_phone);
  const [country, setUserCountry] = useState(user_country);
  const [userWallet, setUserWallet] = useState<UserWallet>(user_wallet);

  const [eventTickets, setEventTickets] = useState<Array<Ticket>>([]);
  const [userWallets, setUserWallets] = useState<Array<UserWallet>>([]);
  const [selectedTicket, setSelectedTicket] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [userDetails, setUserDetails] = useState<any>(null);
  const [qasaPin, setQasaPin] = useState<any>("");
  const [paymentMode, setPaymentMode] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [network, setNetwork] = useState("");
  const [discountedAmount, setDiscountedAmount] = useState("");
  const [promoCodeUsed, setPromoCodeUsed] = useState(false);
  const [value, setValue] = useState<number>(1);
  const [retry_count, setRetryCount] = useState<number>(1);
  const [showPaymentStripe, setShowStripePayment] = useState(false);
  const [showPaymentHubtel, setShowHubtelPayment] = useState(false);
  const [stripeElementLoaded, setStripeElementLoaded] = useState(false);
  const [stripeOptions, setStripeOptions] = useState<any>({});
  const [adData, setAdData] = useState<any>(null);
  const [hubtelUrlToRender, setHubtelUrlToRender] = useState<any>();
  const navigate = useNavigate();
  const [checked, setChecked] = React.useState(false);
  const [subTotal, setSubTotal] = useState(ticket_subTotal);
  const [fees, setTicketsFee] = useState(ticket_fees);

  const stripePromise = loadStripe(
    process.env.REACT_APP_NODE_ENV == "development"
      ? process.env.REACT_APP_STRIPE_DEV_KEY ?? ""
      : process.env.REACT_APP_STRIPE_PROD_KEY ?? ""
  ,);

  const [checkout, setCheckout] = useState(false);

  const [currency, setCurrency] = useState(ticket_currency);
  const [ticketData, setTicketData] = useState<TicketInfo[]>(tickets_data);
  const [customFields, setCustomFields] = useState<CustomFields[]>([]);
  const [customFieldValues, setFieldValues] = useState(
    customFields.map((field) => ({ id: field.id, value: "" }))
  );
  const [isCashPayment, setIsCashPayment] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [showCheckTransactionModal, setShowCheckTransactionModal] =
    useState<boolean>(false);
  const [transactionIdToCheck, setTransactionIdToCheck] = useState("");

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "70%" : 400,
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
  };


  const getAd = useCallback(async () => {
    try {
      const adRes = await apiGetRequest(`${urls.advertisement}`, {
        auth: undefined,
        content_type: ContentTypeEnum.APPLICATION_JSON,
      });

      if (adRes?.code === "00") {
        setAdData(adRes.data);
      }
    } catch (error) {
      console.error("Error fetching ad:", error);
    }
  }, []);

  const getUserData = async () => {
    const storedData = localStorage.getItem("userDetails");
    if (storedData) {
      try {
        const userDetailsObject = JSON.parse(storedData);

        setUserDetails(userDetailsObject);
      } catch (error) {
        localStorage.clear();
        EventRegister.emit(EventsEnum.LoginUser, true);
      }
    }
  };

  const eventListenerRef = useRef<any>(null);
  const userDetailsRef = useRef<any>(null);

  const initFunction = useCallback(async () => {
    setPurchasedTicket(purchasedTickets);
    setSubTotal(ticket_subTotal);
    setTicketsFee(ticket_fees);
    setUserPhone(user_phone);
    setUserCountry(user_country);
    setUserWallet(user_wallet);
    setTicketData(tickets_data);
    setUserDetails(user_data);
    userDetailsRef.current = user_data;
  }, [
    purchasedTickets,
    ticket_subTotal,
    ticket_fees,
    user_phone,
    user_country,
    user_wallet,
    tickets_data,
    user_data,
  ]);
  const setValues = async ({
    u_wallet,
    u_data,
    count,
  }: {
    u_wallet: any;
    u_data: any;
    count: any;
  }) => {
    setUserWallet(u_wallet);
    setUserCountry(count);
    setUserDetails(u_data);
  };

  const getSetWallet = useCallback(async () => {
    const storedData = localStorage.getItem("userDetails");
    if (storedData) {
      try {
        const userDetailsObject = JSON.parse(storedData);
        setUserDetails(userDetailsObject);
        userDetailsRef.current = userDetailsObject;

        const res = await apiGetRequest(`${urls.wallet}`, {
          auth: userDetailsObject?.access_token,
          content_type: ContentTypeEnum.APPLICATION_JSON,
        });

        if (res?.data) {
          setUserWallet(res?.data[0]);
        }
        setUserPhone(userDetailsObject.phone);
        setUserCountry(userDetailsObject.country);
      } catch (error) {
        console.error("Error in getSetWallet:", error);
        localStorage.clear();
        EventRegister.emit(EventsEnum.LoginUser, true);
      }
    }
  }, []);

  const handleCheckLoginStatus = useCallback(
    (data: boolean) => {
      if (data) {
        getSetWallet();
      }
    },
    [getSetWallet]
  );

  useEffect(() => {
    toast(
      "Kindly fill out the form and click the 'Initiate Purchase' button to proceed to the next screen",
      {
        type: ErrorSeverityEnum.success,
      }
    );
    initFunction();
    getAd();
    getSetWallet();

    if (!eventListenerRef.current) {
      eventListenerRef.current = EventRegister.addEventListener(
        EventsEnum.CheckLoginStatus,
        handleCheckLoginStatus
      );
    }

    return () => {
      if (eventListenerRef.current) {
        EventRegister.removeEventListener(eventListenerRef.current);
      }
    };
  }, [initFunction, getSetWallet]);

  const resetForm = () => {
    setUserWallets([]);
    setOpen(false);
    setUserDetails(null);
    setQasaPin("");
    setPaymentMode("");
    setValue(1);
    setRetryCount(1);
    setStripeElementLoaded(false);
    setShowStripePayment(false);
    setPromoCodeUsed(false);
    setChecked(false);
    setDiscountedAmount("");
    setPromoCode("");
    setCheckout(false);
  };

  const handleClose = () => {
    setOpen(false);
    setShowStripePayment(false);
    resetForm();
  };

  const sanitizeTimezone = (timezone: string): string => {
    return timezone.replace(",", "/").replace(" ", "");
  };

  const extractTimeFromDate = (dateStr: string | number | Date) => {
    if (!eventDetails?.event_timezone) {
      return "";
    }

    const date = new Date(dateStr);
    const options: Intl.DateTimeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };

    try {
      const timeString = date.toLocaleTimeString("en-US", options);
      return `${timeString} (${eventDetails?.event_timezone})`;
    } catch (error) {
      console.error("Error formatting time:", error);
      return "";
    }
  };

  function capitalizeText(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  const handlePurchaseTicket = async (
    type: string,
    network: string,
    qasaPin: string,
    cardNumber: string,
    cardExpiry: string,
    cardCVV: string,
    isEmail: boolean,
    email: string,
    uDetails?: any
  ) => {
    setPaymentMode(type);
    setNetwork(network);

    if (type === "cash") {
      setIsCashPayment(true);
    } else {
      setIsCashPayment(false);
    }

    // console.log("THISI S U DETAILS:::::", uDetails);

    let res;
    await getUserData();
    if (uDetails || userDetails) {
      res = await apiGetRequest(`${urls.wallet}`, {
        auth: uDetails?.access_token ?? userDetails?.access_token,
        content_type: ContentTypeEnum.APPLICATION_JSON,
      });

      if (res?.data) {
        setUserWallet(res?.data[0]);
      }
      setUserPhone((uDetails ?? userDetails)?.phone);
      setUserCountry((uDetails ?? userDetails).country);
      setUserDetails(uDetails ?? userDetails);
      // return;
    }

    // console.log("THIS IS IT::", type);
    const baseDets = {
      ...(isPromoCodeUsed ? { promo_code: ticket_promo_code } : {}),
      ...(isEmail ? { user_email: email } : {}),
      wallet_id: userWallet?.id,
      tickets_data: ticketData,
      total_amount: subTotal + fees,
      source_account_type: type == "paypal" ? "card" : type,
      source_account_type_provider: type == "paypal" ? type : "",
      custom_fields: customFieldValues,
    };

    // console.log("THIS IS USER DETAILS::::", baseDets);
    const requestData =
      type === "qasa_wallet"
        ? {
            ...baseDets,
            pin_data: { pin: qasaPin, retry_count: retry_count },
          }
        : type === "card"
        ? {
            ...baseDets,
            ...((uDetails?.country ?? userDetails?.country) == "Ghana"
              ? {
                  card_details: {
                    cvc: cardCVV,
                    expiry: cardExpiry,
                    number: cardNumber,
                  },
                }
              : { currency: "usd" }),
          }
        : type === "paypal"
        ? {
            ...baseDets,
            ...{ currency: "usd" },
          }
        : {
            ...baseDets,
            phone_number: `0${`${
              uDetails?.phone ?? userDetails?.phone ?? user_phone
            }`.substring(3)}`,
            provider: network,
          };

    const resp = await apiPostRequest(
      "/payment/purchase-multiple-ticket",
      requestData,
      {
        auth: uDetails?.access_token ?? userDetails?.access_token,
        content_type: ContentTypeEnum.APPLICATION_JSON,
      }
    );

    if (resp.code == "00") {
      toast(
        type === "cash"
          ? "Ticket reserved successfully. Please pay at the door."
          : subTotal + fees > 0
          ? (type === "qasa_wallet"
              ? resp.msg
              : type == "paypal"
              ? "Kindly complete the payment for this request in the popup page"
              : type === "card"
              ? (uDetails?.country ?? userDetails?.country) == "Ghana"
                ? "Card payment has been successfully initiated. Please enter your card details. You will receive a message once it's successful."
                : "Kindly enter your card details to complete the ticket purchase process."
              : "Please complete the mobile money process on your mobile phone.") ??
            "Action Successful"
          : "Free ticket successfully purchased",
        {
          type: ErrorSeverityEnum.success,
        }
      );

      // if (resp.data.receipt_id && resp.data.id) {
      //   window.open(
      //     `${baseReceiptUrl}${resp.data.receipt_id}-${resp.data.id}`,
      //     "_blank"
      //   );
      // }
      if (resp.data?.payment_details?.approvalUrl) {
        window.open(`${resp.data.payment_details.approvalUrl}`, "_blank");
      }

      if (type === "cash") {
        navigate("/payment-success");
      } else if (subTotal + fees <= 0 || type === "qasa_wallet") {
        navigate(`/payment-success${resp.data.receipt_id ? `?receipt_id=${resp.data.receipt_id}-${resp.data.id}` : ''}`);
      }
      if (subTotal + fees <= 0 || type === "qasa_wallet") {
        navigate(`/payment-success${resp.data.receipt_id ? `?receipt_id=${resp.data.receipt_id}-${resp.data.id}` : ''}`);
      }

      if (
        type == "card" &&
        (uDetails?.country ?? userDetails?.country) != "Ghana" &&
        resp.data.payment_details.clientSecret
      ) {
        setStripeOptions({
          // passing the client secret obtained from the server
          clientSecret: resp.data.payment_details.clientSecret,
        });
        setOpen(false);
        setShowStripePayment(true);
        return;
      } else if (
        type == "card" &&
        (uDetails?.country ?? userDetails?.country) == "Ghana" &&
        resp.data.payment_details.redirectUrl
      ) {
        // setRedirectUrl(resp.data.payment_details.redirectUrl);
        // setHubtelUrlToRender(resp.data.payment_details.redirectUrl);
        window.open(resp.data.payment_details.redirectUrl, "_blank");
        navigate(-1);
        setOpen(false);
        // setShowHubtelPayment(true);
      }
      if (resp.data?.transaction_id) {
        setShowCheckTransactionModal(true);
        setTransactionIdToCheck(resp.data?.transaction_id);
        return;
      }
      navigate(`/e/${eventDetails.purl}`);
    } else if (type === "cash") {
      toast(resp?.msg, {
        type: ErrorSeverityEnum.error,
      });
    } else {
      toast(errorMessage, {
        type: ErrorSeverityEnum.error,
      });
    }
    resetForm();
    
  };

  function handleFieldChange(id: number, value: string) {
    setFieldValues((prevValues) =>
      prevValues.map((field) => (field.id === id ? { ...field, value } : field))
    );
  }


    useEffect(() => {
      const intervalId = setInterval(async () => {
        // console.log('Function runs every 5 seconds');
        // Your function logic here
        if(showCheckTransactionModal){
          const resp = await apiPostRequest(
            "/payment/check-status",
            {
              transactionId: transactionIdToCheck
            },
            {
              auth: userDetails?.access_token,
              content_type: ContentTypeEnum.APPLICATION_JSON,
            },
            false
          );

          if(resp?.data){
            if(resp.data.transaction_status == "SUCCESSFUL"){
              navigate(`/payment-success?receipt_id=${resp.data.receipt_id}`);
            }
          }

        // if (resp?.data) {
        //   if (resp.data.transaction_status == "SUCCESSFUL") {
        //     navigate("/payment-success");
        //   }
        // }
      }
    }, 5000);

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [showCheckTransactionModal]);

  return (
    <>
      <MetaTags
        title={eventDetails?.event_name}
        purl={eventDetails?.purl}
        description={eventDetails?.description}
        image={eventDetails?.poster}
      />
      <Modal
        open={showCheckTransactionModal}
        onClose={() => {
          setShowCheckTransactionModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, backgroundColor: "#f7f8fb" }}>
          {eventDetails && (
            // && userWallets.length > 0
            <Row mainAxisAlignment="space-between">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {`Checking Transaction Status`}
              </Typography>
              <div
                onClick={() => {
                  setShowCheckTransactionModal(false);
                }}
              >
                <CloseIcon />
              </div>
            </Row>
          )}
          <Column
            crossAxisAlignment="center"
            spacing={20}
            sx={{ margin: "4rem 0" }}
          >
            <CircularProgress color="inherit" />
            Checking transaction completion
          </Column>
        </Box>
      </Modal>
      <Modal
        open={showPaymentHubtel}
        onClose={() => {
          setShowHubtelPayment(false);
          resetForm();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, backgroundColor: "#f7f8fb" }}>
          {eventDetails && (
            // && userWallets.length > 0
            <Row mainAxisAlignment="space-between">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {`Purchase "${eventDetails.event_name}" ticket)`}
              </Typography>
              <Container
                onClick={() => {
                  handleClose();
                }}
              >
                <CloseIcon />
              </Container>
            </Row>
          )}
          <iframe
            title="urlRenderer"
            src={hubtelUrlToRender}
            width="100%"
            height="500px"
            frameBorder="0"
          ></iframe>
        </Box>
      </Modal>
      <>
        <Modal
          open={showPaymentStripe}
          onClose={() => {
            setShowStripePayment(false);
            resetForm();
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {eventDetails && (
              // userWallets.length > 0 &&
              <Row mainAxisAlignment="space-between">
                <Typography id="modal-modal-title" variant="h6" component="h2" style={{width:"100%x"}}>
                  {`Purchase "${eventDetails.event_name}" ticket (${currency}
                        ${subTotal + fees})`}
                </Typography>
                <Container
                  sx={{ maxWidth: 20 }}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <CloseIcon />
                </Container>
              </Row>
            )}
            {!stripeElementLoaded && (
              <Container
                sx={{
                  width: "100%",
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                  margin: "2rem 0",
                }}
              >
                <CircularProgress color="inherit" />
              </Container>
            )}
            <Elements stripe={stripePromise} options={stripeOptions}>
              <CheckoutForm
                clientSecret={stripeOptions.client_secret}
                onReady={() => setStripeElementLoaded(true)}
              />
            </Elements>
          </Box>
        </Modal>
        <>
          <FullWidthColumn spacing={20}>
            {eventDetails && (
              <FullWidthColumn>
                <CheckOutOptions
                  phone={user_phone}
                  country={country}
                  userWallet={userWallet}
                  eventData={eventDetails}
                  eventPrice={ticket_subTotal + ticket_fees}
                  callBack={handlePurchaseTicket}
                  checkout={true}
                  promoCodeUsed={isPromoCodeUsed}
                  isCashPayment={isCashPayment}
                  allow_cash_payments={eventDetails?.allow_cash_payments}
                  setWallet={(wallet: any) => setUserWallet(wallet)}
                  setValues={setValues}
                />
                <Box
                  // component="img"
                  sx={{
                    height: "50vh",
                    width: "100%",
                    objectFit: "cover",
                    mb: "2rem",
                  }}
                >
                  <VaryingWidthImage
                    imageUrl={eventDetails.poster}
                    blurImageUrl={eventDetails.poster}
                    // imageList={eventDetails.image_sliders}
                    imageList={(eventDetails?.image_sliders ?? []).map(
                      (x: any) => x.image_link
                    )}
                    type="details_page"
                  />
                </Box>
                <div className="flex-container">
                  <div className="flex-item">
                    <div className="events">
                      <HeaderGreenTopBar />
                      <h2 className="event-content-header">
                        EVENT INFORMATION
                      </h2>
                      <p className="event-content-sub-header">
                        Event Name<br></br>
                        <span className="event-content-sub-info">
                          {" "}
                          {eventDetails.event_name}
                        </span>
                      </p>
                      <p className="event-content-sub-header">
                        Event Date<br></br>
                        <span className="event-content-sub-info">
                          {" "}
                          {
                  convertDateTimeToUsersTimezone(
                    eventDetails.event_date,
                    eventDetails.event_timezone
                  ).date
                }
                        </span>
                      </p>
                      <p className="event-content-sub-header">
                        Event Time<br></br>
                        <span className="event-content-sub-info">
                        {" "}
                        {
                  convertDateTimeToUsersTimezone(
                    eventDetails.event_date,
                    eventDetails.event_timezone
                  ).time
                }
                        </span>
                      </p>
                      <p className="event-content-sub-header">
                        Event Address<br></br>
                        <span className="event-content-sub-info">
                          {eventDetails.venue}
                        </span>
                      </p>
                      <p className="event-content-sub-header">
                        Event Description<br></br>
                        <span className="event-content-sub-info">
                          {eventDetails.description ??
                            "Checkout this awesome event"}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="vertical-separator"></div>
                  <div className="flex-item">
                    <div className="events">
                      <HeaderGreenTopBar />
                      <h2 className="event-content-header">EVENT TICKETS</h2>

                      {purchasedTicket.map((purchaseTicket, index) => (
                        <div className="event-pricing-content" key={index}>
                          <div className="pricing-info">
                            {purchaseTicket.ticket_name}
                            <br></br>
                            <span className="event-content-sub-info">
                              {purchaseTicket.ticket_price != 0
                                ? purchaseTicket.ticket_currency
                                : ""}
                              {purchaseTicket.ticket_price != 0
                                ? purchaseTicket.ticket_price
                                : "Free"}
                              &nbsp;
                            </span>
                            <br></br>
                            <br></br>
                          </div>
                        </div>
                      ))}

                      {customFields.map((custom_field, index) => (
                        <>
                          <div className="custom_title">
                            <strong>
                              {" "}
                              {capitalizeText(custom_field.field_name)}
                            </strong>
                          </div>
                          <StyledTextField
                            sx={{
                              "& fieldset": { border: "none" },
                              width: "100%",
                              maxWidth: "250px",
                              marginTop: "0px",
                              marginBottom: "5px",
                            }}
                            InputProps={{
                              inputProps: {
                                style: {
                                  textAlign: "left",
                                  borderRadius: "100rem",
                                  background: "rgba(255, 255, 255, 0.694)",
                                  height: "20px",
                                },
                              },
                            }}
                            onChange={(e) => {
                              handleFieldChange(
                                custom_field.id,
                                e.target.value
                              );
                            }}
                            margin="normal"
                            label={custom_field.field_name}
                            name={custom_field.field_name}
                          />
                        </>
                      ))}

                      <div className="event-pricing-content">
                        Sub Total
                        <br></br>
                        {currency}
                        {subTotal}
                      </div>

                      <br />
                      <div className="event-pricing-content">
                        Tax
                        <br></br>
                        {currency}
                        {0}
                      </div>
                      <br />
                      <div className="event-pricing-content">
                        Fees
                        <br></br>
                        {currency}
                        {fees}
                      </div>
                      <br />
                      <div className="amount-total">
                        <strong>Total</strong>
                        <br></br>
                        {currency}
                        {subTotal + fees}
                      </div>
                    </div>
                  </div>
                </div>
              </FullWidthColumn>
            )}
          </FullWidthColumn>
        </>
      </>
    </>
  );
}

export default EventCheckout;
