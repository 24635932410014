import { Typography, CircularProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { baseReceiptUrl } from '../_shared/constants';

const styles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  gap: '1rem'
};

const checkmarkStyle: React.CSSProperties = {
  fontSize: 100,
  color: 'green'
};

const PaymentSuccessPage = () => {
  const [countdown, setCountdown] = useState<number>(10);
  const location = useLocation();
  
  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown > 0 ? prevCountdown - 1 : prevCountdown);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      const searchParams = new URLSearchParams(location.search);
      const receiptId = searchParams.get('receipt_id');
      
      if (receiptId) {
        window.location.href = `${baseReceiptUrl}${receiptId}`;
      } else {
        const loc = searchParams.get("loc");
        if (loc === 'card') {
          window.location.href = '/';
        } else {
          window.history.go(-2);
        }
      }
    }
  }, [countdown, location]);

  return (
    <div style={styles}>
      <div style={checkmarkStyle}>&#10004;</div>
      <Typography variant="h5">Payment Successful</Typography>
      <Typography variant="h6" className="text-center">
        We will be sending you an SMS or Email receipt shortly.
      </Typography>
      <Typography variant="subtitle1">
        Redirecting in {countdown} seconds...
      </Typography>
      <CircularProgress />
    </div>
  );
};

export default PaymentSuccessPage;