// HomePage.tsx
import React, { useEffect } from "react";
import "./css/homePage.css";
import assets1 from "../../_shared/assets/microsite/1.jpg";
import assets2 from "../../_shared/assets/microsite/2.jpg";
import assets3 from "../../_shared/assets/microsite/3.jpg";
import assets4 from "../../_shared/assets/microsite/4.jpg";
import assets5 from "../../_shared/assets/microsite/5.webp";
import assets6 from "../../_shared/assets/microsite/6.jpg";
import assets7 from "../../_shared/assets/microsite/7.jpg";
import Google from "../../_shared/assets/microsite/google-play.png";
import QasaMobile from "../../_shared/assets/microsite/qasa_home.jpg";
import MicroNavbar from "./MicroHeader";
import MicroFooter from "./MicroFooter";


const MicroHomePage = () => {
  useEffect(() => {
    const handleScroll = () => {
      const overlayText = document.querySelector(
        ".overlay-text"
      ) as HTMLElement;
      if (overlayText) {
        const scrollPosition = window.scrollY;
        overlayText.style.transform = `translate(-50%, calc(-50% - ${
          scrollPosition / 2
        }px))`;
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Random background image
    const images = [
      assets1,
      assets2,
      assets3,
      assets4,
      assets5,
      assets6,
      assets7,
    ];
    const randomIndex = Math.floor(Math.random() * images.length);
    const imgElement = document.createElement("img");
    imgElement.src = images[randomIndex];
    imgElement.alt = "Parallax Image";

    const parallaxContainer = document.getElementById("parallax-container");
    if (parallaxContainer && !parallaxContainer.querySelector("img")) {
      parallaxContainer.appendChild(imgElement);
    }

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="body-wrapper">
      <MicroNavbar />

      <div className="parallax" id="parallax-container">
        <div className="container-wrapper" id="overlay-container">
          <p className="overlay-text">
            Welcome to QASA
          </p>
        </div>
      </div>

      <div className="container-wrapper section-padding">
        <div className="content-grid">
          <div className="content-column-large" id="home-text">
            <div className="content-box">
              <p className="qasa-description">
                The Qasa experience is one of a kind. With our mobile app, you can have the
                features of "WhatsApp" and "CashApp" in a single app. How cool can that be.
                Envision yourself in a world of unhinged communication as well as seamless peer-to-peer
                payments. You read that right, that is Qasa.
              </p>
              <p className="download-text">
                Download Qasa now!
              </p>
              <div className="download-wrapper">
                <a href="https://apps.apple.com/us/app/qasa-app/id6464432973">
                  <img
                    src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/en-us?size=250x83&releaseDate=1586908800"
                    alt="Download on the App Store"
                    title="Download on the App Store"
                  />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.dubtel.qasa&pcampaignid=web_share"
                   title="Download Qasa on Google Playstore">
                  <img
                    src={Google}
                    alt="Download Qasa on Google Playstore"
                    className="fluid-image inline-image"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="content-column-small">
            <div className="content-box">
              <img src={QasaMobile} className="fluid-image" alt="Qasa App" />
            </div>
          </div>
        </div>
      </div>

      <MicroFooter />
    </div>
  );
};

export default MicroHomePage;