import styled from "styled-components";
import { Column, Row } from "../_shared/styledComponents";
import { BodyText2, HeaderGreenTopBar, HeaderText } from "../_shared/styled";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import VaryingWidthImage from "../_shared/components/imageViewer";
import QrCodeGenerator from "../Event_Admin/QrCodeGenerator";
import { useCallback, useEffect, useRef, useState } from "react";
import { apiGetRequest, apiPostRequest } from "../_shared/api";
import { errorMessage, urls } from "../_shared/constants";
import { useLocation, useNavigate } from "react-router-dom";
import ShareSvg from "../_shared/assets/svg/send2.svg";
import AuthPage, { StyledTextField } from "../Auth/AuthPage";
import { ContentTypeEnum } from "../_shared/enum/content-type.enum";
import { ErrorSeverityEnum } from "../_shared/enum/error-severity.enum";
import { toast } from "react-toastify";
import { CountryCodes, EventsEnum } from "../_shared/types";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import { isNumber, sortTickets } from "../_shared/services";
import { EventRegister } from "react-native-event-listeners";

const PaymentConfirmation = () => {
  const [ticketIdsToTransferList, setTicketIdsToTransferList] = useState<
    Array<any>
  >([]);
  const [receiptDetails, setReceiptDetails] = useState<Array<any>>([]);
  const [eventId, setEventId] = useState<any>({});
  const [showLogin, setShowLogin] = useState(false);
  const [openTransferDialog, setOpenTransferDialog] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  // const [recPhoneNumber, setRecipientPhone] = useState<any>(null);
  const [country, setCountry] = useState<CountryCodes>({}); // Flag to indicate paste event
  const [countryCode, setCountryCode] = useState<any>(""); // State to hold the selected country code
  const [phoneNumber, setPhoneNumber] = useState<any>(""); // State to hold the selected country code
  const [userDetails, setUserDetails] = useState<any>(null);
  const location = useLocation();
  const navigator = useNavigate();
  const { eventData } = location.state || { eventData: {} };
  const theme = useTheme();

  const isMatch = useMediaQuery(theme.breakpoints.down("lg"));

  const userDetailsRef = useRef<any>(null);
  const eventListenerRef = useRef<any>(null);

  const initFunction = useCallback(async () => {
    const storedData = localStorage.getItem("userDetails");

    if (storedData) {
      try {
        const userDetailsObject = JSON.parse(storedData);
        setUserDetails(userDetailsObject);
        userDetailsRef.current = userDetailsObject;
      } catch (error) {
        localStorage.clear();
        setUserDetails(null);
        userDetailsRef.current = null;
      }
    } else {
      setUserDetails(null);
      userDetailsRef.current = null;
    }
  }, []);

  const initFunct = useCallback(async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const receipt_id = searchParams.get("receipt_id");
    const receipt_ids = searchParams.get("receipt_ids");
    const event_id = searchParams.get("event_id");

    if (receipt_id) {
      const res = await apiGetRequest(`${urls.receiptDetails}${receipt_id}`);

      if (res?.data != null) {
        setReceiptDetails([res.data]);
      }
      if (res?.msg != "00") {
        // Handle success if needed
      } else {
        toast(errorMessage, {
          type: ErrorSeverityEnum.error,
        });
      }
    } else if (receipt_ids) {
      let list: Array<any> = [];
      for (var id of receipt_ids.split(",")) {
        const res = await apiGetRequest(`${urls.receiptDetails}${id}`);

        if (res?.data != null && res?.code == "00") {
          list = [...list, res.data[0]];
        }
        if (res?.msg != "00") {
          // Handle success if needed
        } else {
          toast(res?.msg ?? "Failed to fetch receipt data", {
            type: ErrorSeverityEnum.error,
          });
        }
      }
      setReceiptDetails(list);
    }
    if (event_id) {
      setEventId(event_id);
    }
  }, []);

  const transferTicket = async () => {
    setOpenTransferDialog(false);
    const parts = countryCode.split(" ", 2);
    const code = parts[0];
    const selectedCountry = countryCode.substring(parts[0].length + 1);
    const internationalNumber = `${code}${
      phoneNumber[0] == "0" ? phoneNumber.substring(1) : phoneNumber
    }`;
    const transferTicketRes = await apiPostRequest(
      "/purchased_ticket/share_ticket",
      {
        recipient_phone: internationalNumber,
        purchased_ticket_id_list: ticketIdsToTransferList,
        country: selectedCountry,
      },
      {
        auth: userDetails.access_token,
        content_type: ContentTypeEnum.APPLICATION_JSON,
      }
    );

    if (transferTicketRes?.code == "00") {
      // toast(transferTicketRes?.msg ?? "Ticket Transferred", {
      //   type: ErrorSeverityEnum.success,
      // });

      // window.location.reload();
      setTicketIdsToTransferList([]);
      if (sortTickets(receiptDetails).length == 1) {
        navigator("/my-events");
      }
      initFunction();
      initFunct();
    } else {
      setOpenTransferDialog(true);
      toast(errorMessage, {
        type: ErrorSeverityEnum.error,
      });
    }
  };

  const handleCountryCodeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setCountryCode(event.target.value);
  };
  const getCountryCodes = useCallback(async () => {
    const adRes = await apiGetRequest(`${urls.country}`, {
      auth: undefined,
      content_type: ContentTypeEnum.APPLICATION_JSON,
    });

    if (adRes?.code === "00") {
      const countries: CountryCodes = {};
      [...adRes.data].forEach((element: any) => {
        countries[`${element.country_code}`] = {
          code: `${element.dialing_prefix}`.substring(1),
          name: `${element.country_name_in_english}`,
        };
      });

      setCountry(countries);
    }
  }, []);

  useEffect(() => {
    initFunction();
    initFunct();

    if (!eventListenerRef.current) {
      eventListenerRef.current = EventRegister.addEventListener(
        EventsEnum.CheckLoginStatus,
        (data: boolean) => {
          if (data) {
            localStorage.clear();
            setUserDetails(null);
            userDetailsRef.current = null;
            // Additional cleanup if needed
          }
        }
      );
    }

    return () => {
      if (eventListenerRef.current) {
        EventRegister.removeEventListener(eventListenerRef.current);
      }
    };
  }, [initFunction, initFunct]);

  useEffect(() => {
    getCountryCodes();
  }, [getCountryCodes]);

  function formatDate(dateString: string): string {
    if (!dateString) {
      return '""';
    }

    let date: Date;
    if (dateString.startsWith("today")) {
      date = new Date();
      if (dateString.includes("+")) {
        const daysToAdd = parseInt(dateString.split("+")[1], 10);
        date.setDate(date.getDate() + daysToAdd);
      }
    } else {
      date = new Date(dateString);
      if (isNaN(date.getTime())) {
        return '""';
      }
    }

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const eventDate = receiptDetails[0]?.event_date ?? "";
  const eventEndDate = receiptDetails[0]?.end_date ?? "";

  const startDate = formatDate(eventDate);
  const endDate = formatDate(eventEndDate);

  function formatTime(dateTimeString: string): string {
    if (!dateTimeString) {
      return '""';
    }

    let date: Date;
    if (dateTimeString.startsWith("today")) {
      date = new Date();
      if (dateTimeString.includes("+")) {
        const daysToAdd = parseInt(dateTimeString.split("+")[1], 10);
        date.setDate(date.getDate() + daysToAdd);
      }
    } else {
      date = new Date(dateTimeString);
      if (isNaN(date.getTime())) {
        return "";
      }
    }

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  }

  const startTime = formatTime(eventDate);
  const endTime = formatTime(eventEndDate);

  const timezoneAbbreviations: { [key: string]: string } = {
    "-12:00": "BIT",
    "-11:00": "SST",
    "-10:00": "HST",
    "-09:00": "AKST",
    "-08:00": "PST",
    "-07:00": "MST",
    "-06:00": "CST",
    "-05:00": "EST",
    "-04:00": "AST",
    "-03:00": "ART",
    "-02:00": "GST",
    "-01:00": "CVT",
    "+00:00": "GMT",
    "+01:00": "CET",
    "+02:00": "EET",
    "+03:00": "MSK",
    "+04:00": "GST",
    "+05:00": "PKT",
    "+05:30": "IST",
    "+06:00": "BST",
    "+07:00": "ICT",
    "+08:00": "CST",
    "+09:00": "JST",
    "+10:00": "AEST",
    "+11:00": "AEDT",
    "+12:00": "NZST",
  };

  const convertTimezone = (timezoneValue: string): string | undefined => {
    if (!timezoneValue) return undefined;

    const match = timezoneValue.match(/UTC([+-])(\d+)(?::(\d+))?/);
    if (!match) return undefined;

    const [, sign, hours, minutes = "00"] = match;
    const formattedOffset = `${sign}${hours.padStart(2, "0")}:${minutes}`;

    return timezoneAbbreviations[formattedOffset] || `UTC${formattedOffset}`;
  };

  // console.log(convertTimezone(receiptDetails[0]?.event_timezone), "timezone");

  const extractTimeFromDate = (dateStr: string | number | Date) => {
    if (!receiptDetails[0]?.event_timezone) {
      return "";
    }

    const date = new Date(dateStr);
    const options: Intl.DateTimeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };

    try {
      const timeString = date.toLocaleTimeString("en-US", options);
      return `${timeString} (${receiptDetails[0]?.event_timezone})`;
    } catch (error) {
      console.error("Error formatting time:", error);
      return "";
    }
  };

  return (
    <PaymentConfirmationWrapper
      style={{ padding: isMatch ? "5rem 1rem" : "5rem" }}
    >
      <Column>
        <HeaderGreenTopBar />
        <HeaderText>PAYMENT CONFIRMATION</HeaderText>
      </Column>
      {!receiptDetails ? (
        <></>
      ) : (
        <>
          <Box
            sx={{
              height: "50vh",
              width: "100%",
              objectFit: "cover",
              margin: "5rem 0",
            }}
          >
            <VaryingWidthImage
              imageUrl={receiptDetails[0]?.poster}
              blurImageUrl={receiptDetails[0]?.poster}
            />
          </Box>

          <EventWrapper style={{ borderRadius: "15px" }}>
            <Column style={{ width: "35%", margin: "2rem" }}>
              <HeaderGreenTopBar />
              <HeaderText style={{ marginBottom: "2rem" }}>
                EVENT INFORMATION
              </HeaderText>
              <GridContainer>
                <GridItem>
                  <BodyHeader>Event Name</BodyHeader>
                  <BodyText>{receiptDetails[0]?.event_name}</BodyText>
                </GridItem>
                <GridItem>
                  <BodyHeader>Event Date</BodyHeader>
                  <BodyText>
                    {(receiptDetails[0]?.event_date ?? "").length > 0
                      ? receiptDetails[0]?.event_date
                          .split(" ")
                          .slice(0, 4)
                          .join(" ")
                      : ""}
                  </BodyText>
                </GridItem>
                <GridItem>
                  <BodyHeader>Event Time</BodyHeader>
                  <BodyText>
                    {extractTimeFromDate(receiptDetails[0]?.event_date)}
                  </BodyText>
                </GridItem>
                <GridItem>
                  <BodyHeader>Event Address</BodyHeader>
                  <BodyText>{receiptDetails[0]?.venue}</BodyText>
                </GridItem>
                {receiptDetails[0]?.description && (
                  <GridItem>
                    <BodyHeader>Event Description</BodyHeader>
                    <BodyText>{receiptDetails[0]?.description}</BodyText>
                  </GridItem>
                )}
              </GridContainer>
            </Column>
            {!isMatch && (
              <div
                style={{
                  height: "20rem",
                  borderLeft: "dashed 2px #595F67",
                  width: "10px",
                  margin: "2rem 0",
                }}
              />
            )}
            <Column
              style={{
                // borderLeft: isMatch?'none':"dashed 2px #595F67",
                paddingLeft: isMatch ? "0" : "0",
                // marginRight: "3rem",
                margin: "2rem",
              }}
            >
              <HeaderGreenTopBar />
              <HeaderText style={{ marginBottom: "2rem" }}>
                PAYMENT DETAILS
              </HeaderText>
              <GridContainer>
                <GridItem>
                  <BodyHeader>{receiptDetails[0]?.ticket_name}</BodyHeader>
                  <BodyText>
                    {receiptDetails[0]?.price == 0
                      ? "Free"
                      : `${receiptDetails[0]?.currency}${receiptDetails[0]?.price}`}
                  </BodyText>
                </GridItem>
              </GridContainer>

              <div
                style={{
                  marginTop: "40px",
                  fontWeight: "bold",
                  display: "block",
                }}
              >
                <HeaderGreenTopBar />
                <HeaderText style={{ marginBottom: "2rem" }}>
                  Add Events To Calendar
                </HeaderText>
                <div style={{ marginTop: "15px" }}>
                  <AddToCalendarButton
                    name={receiptDetails[0]?.event_name}
                    options={["Apple", "Google", "iCal", "Outlook.com"]}
                    location={receiptDetails[0]?.venue}
                    startDate={startDate}
                    startTime={startTime}
                    endDate={startDate}
                    endTime={endTime}
                    timeZone={convertTimezone(
                      receiptDetails[0]?.event_timezone
                    )}
                    description={receiptDetails[0]?.description}
                    hideBackground
                    buttonsList
                    size="1"
                  ></AddToCalendarButton>
                </div>
              </div>
            </Column>
          </EventWrapper>
          <EventWrapper
            style={{
              margin: "2rem 0",
              marginBottom: "1rem",
            }}
          >
            <HeaderText
              style={{
                fontSize: "15px",
                margin:
                  ticketIdsToTransferList.length > 0 ? "auto 15px" : "15px",
              }}
            >
              QR Codes
            </HeaderText>
            {ticketIdsToTransferList.length > 0 && (
              <Row
                style={{ fontSize: "15px", margin: "15px" }}
                onClick={() => {
                  setOpenTransferDialog(true);
                }}
              >
                <Row crossAxisAlignment="center" spacing={10}>
                  <div
                    style={{
                      marginLeft: "50px",
                      padding: "10px 20px",
                      borderRadius: "15rem",
                      background: "#0CAF6D",
                      color: "white",
                      display: "flex",
                      gap: "1rem",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <span>Transfer Selected Tickets</span>
                  </div>
                </Row>
              </Row>
            )}
          </EventWrapper>
          {sortTickets(receiptDetails).map((recDet) => (
            <Row
              mainAxisAlignment="space-between"
              sx={{ flexWrap: "wrap", gap: "2rem", marginBottom: "2rem" }}
            >
              <Row spacing={20}>
                {recDet?.status == "PURCHASED" && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ticketIdsToTransferList.includes(
                          recDet.purchased_ticket_id
                        )}
                        onChange={(event) => {
                          setTicketIdsToTransferList(
                            event.target.checked
                              ? [
                                  ...ticketIdsToTransferList,
                                  recDet.purchased_ticket_id,
                                ]
                              : ticketIdsToTransferList.filter(
                                  (x: any) => x !== recDet.purchased_ticket_id
                                )
                          );
                          // console.log(ticketIdsToTransferList);
                        }}
                        color="primary"
                      />
                    }
                    label=""
                    style={{
                      color: "rgba(121, 194, 216, 1)",
                      marginTop: "1rem",
                    }}
                  />
                )}
                <QrCodeGenerator
                  url={`${recDet?.purchased_ticket_id},${recDet?.group_id},${eventId}`}
                  size={100}
                  height={100}
                  width={100}
                  color="#000"
                />
                <Column>
                  <Row spacing={10}>
                    <BodyHeader>Ticket #:</BodyHeader>
                    <BodyText>{recDet?.purchased_ticket_id}</BodyText>
                  </Row>
                  <Row spacing={10}>
                    <BodyHeader>Type:</BodyHeader>
                    <BodyText>{recDet?.ticket_name}</BodyText>
                  </Row>
                  <Row spacing={10}>
                    <BodyHeader>Price:</BodyHeader>
                    <BodyText>
                      {recDet?.price == 0
                        ? "Free"
                        : `${recDet.currency}${recDet?.price}`}
                    </BodyText>
                  </Row>
                  {recDet?.status == "TRANSFERRED" && (
                    <BodyText>This ticket has been transferred</BodyText>
                  )}
                </Column>
              </Row>
              {recDet?.status == "PURCHASED" && (
                <Row style={{ marginRight: "10rem" }}>
                  <Row
                    crossAxisAlignment="center"
                    spacing={10}
                    onClick={() => {
                      setTicketIdsToTransferList([recDet.purchased_ticket_id]);
                      setOpenTransferDialog(true);
                    }}
                  >
                    <RhsTransferText style={{ cursor: "pointer" }}>
                      Transfer Ticket
                    </RhsTransferText>
                    <RhsSubContainer>
                      <img
                        style={{
                          width: "34px",
                          height: "34px",
                        }}
                        src={ShareSvg}
                      />
                    </RhsSubContainer>
                  </Row>
                </Row>
              )}
            </Row>
          ))}
          <EventWrapper
            style={{
              margin: "2rem 0",
              marginBottom: "0.5rem",
              // padding: "15px",
            }}
          >
            <HeaderText style={{ fontSize: "15px", margin: "15px" }}>
              Next Steps
            </HeaderText>
          </EventWrapper>
          <BodyText2>
            You will receive an SMS and/or Email with your receipt confirmation.
            If you have any questions, reach out to support@qasa.me.
          </BodyText2>
          {/* <EventWrapper
            style={{
              margin: "2rem 0",
              marginBottom: "0.5rem",
              // padding: "15px",
            }}
          >
            <HeaderText style={{ fontSize: "15px", margin: "15px" }}>
              Confirmation
            </HeaderText>
          </EventWrapper>
          <Column>
          <StyledTextField
            sx={{
              "& fieldset": {
                border: " 1px solid #79C2D8",
                borderRadius: "100rem",
              },
              width: "100%",
              maxWidth: "400px",
            }}
            InputProps={{
              inputProps: {
                style: {
                  textAlign: "left",
                  borderRadius: "100rem",
                  background: "white",
                  height: "20px",
                },
              },
            }}
            onChange={(e) => {
              // console.log(e.target.value);
              // setFirstName(e.target.value);
            }}
            margin="normal"
            id="firstName"
            label="Email"
            name="firstName"
          />
          
          <Button
        variant="contained"
        sx={{
          width: "100%",
          maxWidth: "400px",
          height: "48px",
          borderRadius: "100rem",
          backgroundColor: "#0CAF6D",
          marginTop: "70px",
        }}
        onClick={() => {}}
      >
        Send Confirmation
      </Button> 
          </Column>*/}

          <StyledDialog
            open={openTransferDialog}
            onClose={() => setOpenTransferDialog(false)}
          >
            <HeaderText color="white">TRANSFER TICKET</HeaderText>
            <Row spacing={20} style={{ marginTop: "1rem" }}>
              <div
                style={{
                  background: "white",
                  padding: "10px 20px",
                  height: "120px",
                  borderRadius: "10px",
                }}
              >
                <QrCodeGenerator
                  url={`${receiptDetails[0]?.purchased_ticket_id},${receiptDetails[0]?.group_id},${eventId}`}
                  size={100}
                  height={100}
                  width={100}
                  color="#000"
                />
              </div>
              <Column>
                <Row spacing={10}>
                  <BodyHeader style={{ color: "white" }}>Ticket #:</BodyHeader>
                  <BodyText style={{ color: "white" }}>
                    {receiptDetails[0]?.purchased_ticket_id}
                  </BodyText>
                </Row>
                <Row spacing={10}>
                  <BodyHeader style={{ color: "white" }}>Type:</BodyHeader>
                  <BodyText style={{ color: "white" }}>
                    {receiptDetails[0]?.ticket_name}
                  </BodyText>
                </Row>
                <Row spacing={10}>
                  <BodyHeader style={{ color: "white" }}>Price:</BodyHeader>
                  <BodyText style={{ color: "white" }}>
                    {receiptDetails[0]?.price == 0
                      ? "Free"
                      : `${receiptDetails[0]?.currency}${receiptDetails[0]?.price}`}
                  </BodyText>
                </Row>
              </Column>
            </Row>

            <BodyHeader style={{ color: "white", marginTop: "2rem" }}>
              CAUTION ⓘ
            </BodyHeader>
            <BodyText style={{ color: "white" }}>
              You are about to transfer a purchased ticket. Please note
              transferred tickets cannot be returned or refunded to you.{" "}
            </BodyText>

            <FormControlLabel
              control={
                <RedCheckbox
                  checked={agreeToTerms}
                  onChange={(event) => setAgreeToTerms(event.target.checked)}
                />
              }
              label="I agree. I’d like to transfer my ticket"
              style={{ color: "rgba(121, 194, 216, 1)", marginTop: "1rem" }}
            />

            <Row crossAxisAlignment="center" spacing={10}>
              <Container
                sx={{
                  width: "100%",
                  maxWidth: "500px",
                  background: "rgba(240, 240, 240, 1)",
                  borderRadius: "100rem",
                  height: "55px",
                  marginTop: "10px",
                }}
              >
                <Box display="flex" alignItems="center">
                  {/* Country code dropdown */}
                  <TextField
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                    select
                    value={countryCode}
                    onChange={handleCountryCodeChange}
                    variant="outlined"
                    SelectProps={{
                      displayEmpty: true,
                    }}
                  >
                    {/* Default empty option */}
                    <MenuItem value="" disabled>
                      Select Country Code
                    </MenuItem>

                    {/* Country code options */}
                    {Object.keys(country).map((key: string) => (
                      <MenuItem
                        value={`${country[key].code} ${country[key].name}`}
                      >{`${country[key].code} (${country[key].name})`}</MenuItem>
                    ))}
                    {/* Add more country codes as needed */}
                  </TextField>

                  {/* Spacer between dropdown and TextField */}
                  <Box width={10} />

                  {/* Actual text field for phone number */}
                  <TextField
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    onChange={(event) => {
                      setPhoneNumber(
                        //@ts-ignore
                        !isNaN(event.target.value)
                          ? event.target.value.slice(0, 12)
                          : phoneNumber
                      );
                    }}
                    sx={{
                      "& fieldset": { border: "none" },
                      "& .MuiInputLabel-shrink": {
                        margin: "19px auto",
                        position: "absolute",
                        left: "0",
                        top: "-3px",
                        width: "150px", // Need to give it a width so the positioning will work
                        background: "none", // Add a white background as below we remove the legend that had the background so text is not meshing with the border
                      },
                    }}
                    value={phoneNumber}
                  />
                </Box>
              </Container>

              <Button
                variant="contained"
                disabled={!phoneNumber || !agreeToTerms || !countryCode}
                sx={{
                  width: "100%",
                  maxWidth: "180px",
                  height: "55px",
                  borderRadius: "100rem",
                  backgroundColor: "#0CAF6D",
                }}
                onClick={() => {
                  // navigate(`/e/${eventDetails?.purl}`);
                  transferTicket();
                }}
              >
                Transfer Ticket
              </Button>
            </Row>

            {/* <Row crossAxisAlignment="center" spacing={20} style={{marginTop:"1rem"}}>
          <StyledTextField
          value={recPhoneNumber}
            variant="outlined"
            sx={{
              "& fieldset": {
                border: "1px solid #79C2D8",
                borderRadius: "1000px",
                margin: 0,
              },
              width: "100%",
              maxWidth: "600px",
              margin: 0,
            }}
            InputProps={{
              type:"tel",
              inputProps: {
                style: {
                  textAlign: "left",
                  borderRadius: "100rem",
                  height: "20px",
                  background: "white",
                },
              },
            }}
            onChange={(e) => {
              if(e.target.value == ""){
                setRecipientPhone(null)
              }else{
                setRecipientPhone(e.target.value.slice(0,12));
              }
            }}
            margin="normal"
            id="rPhone"
            label="Recipient's phone e.g. 233550000001"
            name="rPhone"
          />
          <Button
        
            variant="contained"
            disabled={!recPhoneNumber || !agreeToTerms}
            sx={{
              width: "100%",
              height: "55px",
              borderRadius: "100rem",
              backgroundColor: "#0CAF6D",
            }}
            onClick={() => {
              // navigate(`/e/${eventDetails?.purl}`);
              transferTicket();
            }}
          >
            Transfer Ticket
          </Button>
        </Row> */}
          </StyledDialog>
        </>
      )}
    </PaymentConfirmationWrapper>
  );
};

export default PaymentConfirmation;

const PaymentConfirmationWrapper = styled.div``;

const EventWrapper = styled.div`
  /* padding: 2rem; */
  overflow: hidden;
  width: 100%;
  background-color: #edf2f7;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const BodyHeader = styled.span`
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #5f6462;
`;

const BodyText = styled.span`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #5f6462;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
  width: 100%;
`;

const GridItem = styled.div`
  text-align: center; /* Center align text */
  display: flex;
  flex-direction: column;
`;

const RhsSubContainer = styled.div`
  background-color: #0caf6d;
  width: 30px;
  height: 30px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
`;

const RhsTransferText = styled.span`
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: right;
  color: rgba(37, 94, 135, 1);
`;

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    background: rgba(37, 94, 135, 1);
    border-radius: 16px 0 16px 16px;
    padding: 2rem;
    color: white;
  }
`;

// Create a styled version of the Checkbox
const RedCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "red",
  "&.Mui-checked": {
    color: "red",
  },
}));
