import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
} from "@mui/material";
import axios from "axios";
import { apiGetRequest, apiPostRequest } from "../_shared/api";
import { urls } from "../_shared/constants";
import { ContentTypeEnum } from "../_shared/enum/content-type.enum";
import { toast } from "react-toastify";
import { ErrorSeverityEnum } from "../_shared/enum/error-severity.enum";
import { useNavigate } from "react-router-dom";
import { Row } from "../_shared/styledComponents";
import { EventRegister } from "react-native-event-listeners";
import { EventsEnum } from "../_shared/types";

export interface Advertisement {
  id: string; // Replace with actual ID type if it's numeric
  campaign_name: string;
  campaign_description: string;
  campaign_start_date: string;
  campaign_end_date: string;
  campaign_link: string;
  campaign_image_url: string;
  target_country?: string;
  state_province?: string;
  city?: string;
  postal_code?: string;
}

export interface FormData {
  campaign_name: string;
  campaign_description: string;
  campaign_start_date: string;
  campaign_end_date: string;
  campaign_link: string;
  target_country?: string;
  state_province?: string;
  city?: string;
  postal_code?: string;
}

const AdvertisementsPage: React.FC = () => {
  const [advertisements, setAdvertisements] = useState<Advertisement[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>({
    campaign_name: "",
    campaign_description: "",
    campaign_start_date: "",
    campaign_end_date: "",
    campaign_link: "",
    target_country: "",
    state_province: "",
    city: "",
    postal_code: "",
  });
  const [banner, setBanner] = useState<File | null>(null);
  const [responseMessage, setResponseMessage] = useState<string | null>(null);
  const [userDetails, setUserDetails] = useState<any>(null);
  const navigate = useNavigate();
  const fetchAdvertisements = async (accessToken: string) => {
    try {
      const myadverts = await apiGetRequest(
        `/advertisement/my-advertisements`,
        {
          auth: accessToken,
          content_type: ContentTypeEnum.APPLICATION_JSON,
        }
      );

      if (myadverts?.code === "00") {
        setAdvertisements(myadverts?.data ?? []);
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  useEffect(() => {
    const initFunct = async () => {
      const storedData = localStorage.getItem("userDetails");

      if (storedData) {
        try {
          const userDetailsObject = JSON.parse(storedData);
          setUserDetails(userDetailsObject);
          await fetchAdvertisements(userDetailsObject?.access_token);
        } catch (error) {
          navigate("/");
        }
      } else {
        navigate("/");
      }
    };

    initFunct();

    const checkLoginStatusListener: any = EventRegister.addEventListener(
      EventsEnum.CheckLoginStatus,
      (data: boolean) => {
        if (data) {
          initFunct();
        }
      }
    );

    return () => {
      EventRegister.removeEventListener(checkLoginStatusListener);
    };
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files) {
      setBanner(e.target.files[0]);
    }
  };

  const handleDialogClose = (): void => {
    setIsDialogOpen(false);
    setFormData({
      campaign_name: "",
      campaign_description: "",
      campaign_start_date: "",
      campaign_end_date: "",
      campaign_link: "",
      target_country: "",
      state_province: "",
      city: "",
      postal_code: "",
    });
    setBanner(null);
    setResponseMessage(null);
  };

  const handleSubmit = async (e: any): Promise<void> => {
    e.preventDefault();
    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      const value = formData[key as keyof FormData];
      if (value) data.append(key, value);
    });
    if (banner) {
      data.append("banner", banner);
    } else {
      toast("Please add an image", {
        type: ErrorSeverityEnum.error,
      });
      return;
    }

    try {
      const createAdvertRes = await apiPostRequest("/advertisement/add", data, {
        auth: userDetails.access_token,
        content_type: ContentTypeEnum.APPLICATION_JSON,
      });
      if (createAdvertRes?.code === "00") {
        fetchAdvertisements(userDetails.access_token);
        handleDialogClose();
      } else {
        toast(createAdvertRes.msg ?? "Failed", {
          type: ErrorSeverityEnum.error,
        });

        return;
      }
    } catch (error) {
      setResponseMessage("Failed to create advertisement.");
    }
  };

  return (
    <Container>
      <Row mainAxisAlignment="space-between" style={{ marginBottom: "5rem" }}>
        <Typography variant="h4" gutterBottom>
          My Advertisements
        </Typography>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => setIsDialogOpen(true)}
          sx={{
            height: "48px",
            borderRadius: "100rem",
            backgroundColor: "#0CAF6D",
          }}
        >
          Create Advertisement
        </Button>
      </Row>
      {advertisements.length > 0 ? (
        <Grid container spacing={3}>
          {advertisements.map((ad) => (
            <Grid item xs={12} sm={6} md={4} key={ad.id}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image={ad.campaign_image_url}
                  alt="Campaign Image"
                />
                <CardContent>
                  <Typography variant="h6">{ad.campaign_name}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {ad.campaign_description}
                  </Typography>
                  <Typography variant="body2">
                    Start Date: {ad.campaign_start_date}
                  </Typography>
                  <Typography variant="body2">
                    End Date: {ad.campaign_end_date}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    color="primary"
                    href={
                      ad.campaign_link.startsWith("http")
                        ? ad.campaign_link
                        : `https://${ad.campaign_link}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Visit Campaign
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography variant="body1">No advertisements found.</Typography>
      )}

      {/* Dialog for Creating Advertisement */}
      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Create Advertisement</DialogTitle>
        <DialogContent>
          <Box component="form" onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Campaign Name"
              name="campaign_name"
              value={formData.campaign_name}
              onChange={handleInputChange}
              margin="dense"
            />
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Campaign Description"
              name="campaign_description"
              value={formData.campaign_description}
              onChange={handleInputChange}
              margin="dense"
            />
            <TextField
              fullWidth
              type="date"
              label="Start Date"
              name="campaign_start_date"
              value={formData.campaign_start_date}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
              margin="dense"
            />
            <TextField
              fullWidth
              type="date"
              label="End Date"
              name="campaign_end_date"
              value={formData.campaign_end_date}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
              margin="dense"
            />
            <TextField
              fullWidth
              label="Campaign Link"
              name="campaign_link"
              value={formData.campaign_link}
              onChange={handleInputChange}
              margin="dense"
            />
            <TextField
              fullWidth
              label="Target Country"
              name="target_country"
              value={formData.target_country}
              onChange={handleInputChange}
              margin="dense"
            />
            <TextField
              fullWidth
              label="State/Province"
              name="state_province"
              value={formData.state_province}
              onChange={handleInputChange}
              margin="dense"
            />
            <TextField
              fullWidth
              label="City"
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              margin="dense"
            />
            <TextField
              fullWidth
              label="Postal Code"
              name="postal_code"
              value={formData.postal_code}
              onChange={handleInputChange}
              margin="dense"
            />
            <Button variant="contained" component="label" sx={{ marginTop: 2 }}>
              Upload Banner
              <input type="file" hidden onChange={handleFileChange} />
            </Button>
            {banner && <Typography variant="body2">{banner.name}</Typography>}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            sx={{
              width: "100%",
              marginTop: "5rem",
              height: "48px",
              borderRadius: "100rem",
              borderColor: "#0CAF6D",
              color: "#0CAF6D",
            }}
            onClick={handleDialogClose}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{
              width: "100%",
              marginTop: "5rem",
              height: "48px",
              borderRadius: "100rem",
              backgroundColor: "#0CAF6D",
            }}
          >
            Create
          </Button>
        </DialogActions>
        {responseMessage && (
          <Typography
            variant="body2"
            color={responseMessage.includes("Failed") ? "error" : "success"}
            sx={{ textAlign: "center", marginBottom: 2 }}
          >
            {responseMessage}
          </Typography>
        )}
      </Dialog>
    </Container>
  );
};

export default AdvertisementsPage;
