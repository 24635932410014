import React, { useState, useEffect } from 'react';
import './css/navbar.css';
import Qasa from "../../_shared/assets/microsite/Qasa_logo_rectangle.png"

const MicroNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Add body class when menu is open
  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }
    return () => {
      document.body.classList.remove('menu-open');
    };
  }, [isMenuOpen]);

  return (
    <>
      <nav className="navbar" data-bs-theme="light" aria-label="Qasa navbar large">
        <div className="navbar-container">
          <a className="navbar-brand" href="/i">
            <img 
              src={Qasa}
              alt="Qasa Logo" 
              width="100"
            />
          </a>

          <button 
            className="menu-toggle"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label="Toggle navigation"
          >
            <span className="menu-toggle-icon"></span>
          </button>

          <div className={`navbar-menu ${isMenuOpen ? 'open' : ''}`}>
            <div className="menu-header">
              <h5>Qasa</h5>
              <button 
                className="close-button"
                onClick={() => setIsMenuOpen(false)}
                aria-label="Close"
              >
                <span className="close-icon"></span>
              </button>
            </div>

            <ul className="nav-links">
              <li className="nav-item">
                <a className="nav-link" href="/i">Home</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://events.qasa.me/">Events</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/i/downloads">Downloads</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/i/contact">Help</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default MicroNavbar;