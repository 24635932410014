import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "../EventDetails/css/eventDetails.css";
import { apiGetRequest, apiPostRequest, apiPutRequest } from "../_shared/api";
import { HeaderGreenTopBar } from "../_shared/styled";

import { ContentTypeEnum } from "../_shared/enum/content-type.enum";
import { baseReceiptUrl, errorMessage, urls } from "../_shared/constants";
import VaryingWidthImage from "../_shared/components/imageViewer";
import { EventRegister } from "react-native-event-listeners";
import { EventsEnum } from "../_shared/types";
import { toast } from "react-toastify";
import { Container, InputAdornment, styled, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import Add from "../_shared/assets/svg/plus.svg";
import Minus from "../_shared/assets/svg/minus.svg";
import { StyledTextField } from "../Auth/AuthPage";
import {
  Box,
  Typography,
  Button,
  Modal,
  SelectChangeEvent,
  CircularProgress,
} from "@mui/material";
import { FullWidthColumn, Row } from "../_shared/styledComponents";
// import { CheckOutOptions } from "./checkoutOptions";
import { ErrorSeverityEnum } from "../_shared/enum/error-severity.enum";
import {
  TicketInfo,
  UserWallet,
  Ticket,
  EventData,
  CustomFields,
  PurchasedTicket,
} from "./interfaces";
import { CheckoutForm } from "./CheckoutForm";
import { DisplayStatusEnum } from "../Home/_helpers/types";
// import { Helmet } from "react-helmet";
import MetaTags from "../_shared/components/helmets";
import Login from "../Auth/Login";
import { Helmet } from "react-helmet";
import BannerAd from "../Home/css/BannerAd";
import WaitlistButton from "./css/WaitlistButton";
import { convertDateTimeToUsersTimezone } from "../_shared/services";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

interface DotProps {
  isActive: boolean;
}

function EventDetails() {
  const [searchParams] = useSearchParams();
  const [eventDetails, setEventDetails] = useState<EventData>();
  const [eventTickets, setEventTickets] = useState<Array<Ticket>>([]);
  const [ticketList, setTicketList] = useState(eventTickets.map(() => 0));
  const [purchasedTicket, setPurchasedTicket] = useState<
    Array<PurchasedTicket>
  >([]);
  const [userWallets, setUserWallets] = useState<Array<UserWallet>>([]);
  const [selectedTicket, setSelectedTicket] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [userDetails, setUserDetails] = useState<any>(null);
  const [qasaPin, setQasaPin] = useState<any>("");
  const [paymentMode, setPaymentMode] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [eventPoster, setEventPoster] = useState("");
  const [email, setEmail] = useState("");
  const [network, setNetwork] = useState("");
  const [discountedAmount, setDiscountedAmount] = useState("");
  const [promoCodeUsed, setPromoCodeUsed] = useState(false);
  const [value, setValue] = useState<number>(1);
  const [retry_count, setRetryCount] = useState<number>(1);
  const [showPaymentHubtel, setShowHubtelPayment] = useState(false);
  const [adData, setAdData] = useState<any>(null);
  const [subscribedEvent, setSubscribedEvent] = useState<any>();
  const [hubtelUrlToRender, setHubtelUrlToRender] = useState<any>();
  const navigate = useNavigate();
  const [checked, setChecked] = React.useState(false);
  const [ticketSelected, setTicketSelected] = useState(false);
  const [end_date, setEndDate] = useState("Thu, 23 May 2024 18:30:00 GMT");
  const [event_date, setEventDate] = useState("Thu, 23 May 2024 18:30:00 GMT");
  const [event_time, setEventTime] = useState("16:30:00 GMT");
  const [timeZone, setTimeZone] = useState("");
  const [checkout, setCheckout] = useState(false);
  const [ticketCounts, setTicketCounts] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [actualAmount, setActualAmount] = useState(0);
  const [fees, setTicketsFee] = useState(0);
  const [currency, setCurrency] = useState("");
  const [ticketData, setTicketData] = useState<TicketInfo[]>([]);
  const [customFields, setCustomFields] = useState<CustomFields[]>([]);
  const [isPromoCodeValid, setIsPromoCodeValid] = useState("");
  const [soldOutTickets, setSoldOutTickets] = useState<Set<number>>(new Set());
  const [allTicketsSoldOut, setAllTicketsSoldOut] = useState(false);
  const [customFieldValues, setFieldValues] = useState(
    customFields?.map((field) => ({
      id: field.id,
      value: "",
    })) ?? []
  );
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [images, setImages] = useState<string[]>([]);

  const [isMobile, setIsMobile] = useState(false);
  const ticketSectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust this breakpoint as needed
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  useEffect(() => {
    if (isMobile && ticketSectionRef.current) {
      ticketSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isMobile]);

  const scrollToTicketSection = () => {
    if (isMobile && ticketSectionRef.current) {
      ticketSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  // console.log("eventImages", images);

  const getAd = async () => {
    const adRes = await apiGetRequest(`${urls.advertisement}`, {
      auth: undefined,
      content_type: ContentTypeEnum.APPLICATION_JSON,
    });

    if (adRes?.code === "00") {
      setAdData(adRes.data);
    }
  };

  function convertDateFormat(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
  const handlePublish = async () => {
    const eventFormData = new FormData();
    eventFormData.append("event_name", eventDetails?.event_name ?? "");
    eventFormData.append(
      "event_date",
      convertDateFormat(eventDetails?.event_date ?? "")
    );
    eventFormData.append(
      "end_date",
      convertDateFormat(eventDetails?.event_date ?? "")
    );
    eventFormData.append("event_description", eventDetails?.description ?? "");
    eventFormData.append("venue", eventDetails?.venue ?? "");
    eventFormData.append("visibility", eventDetails?.visibility ?? "");
    eventFormData.append("display_status", "published");

    if (eventDetails?.id) {
      if (
        eventDetails?.display_status === DisplayStatusEnum.draft ||
        !eventDetails?.group_id
      ) {
        const createGroupRes = await apiPostRequest(
          "/group/add",
          {
            group_name: eventDetails?.event_name ?? "",
            description: `This is the group for the ${
              eventDetails?.event_name ?? ""
            } event`,
            visibility: eventDetails?.visibility ?? "",
          },
          {
            auth: userDetails.access_token,
            content_type: ContentTypeEnum.APPLICATION_JSON,
          }
        );
        if (createGroupRes?.code === "00") {
          eventFormData.append("group_id", createGroupRes.data.id.toString());
        } else {
          toast(errorMessage, {
            type: ErrorSeverityEnum.error,
          });
          return;
        }
      }
      eventFormData.append(
        "event_show_date",
        convertDateFormat(eventDetails?.event_date ?? "")
      );

      const res = await apiPutRequest(
        `${urls.events}update/${eventDetails?.id}`,
        eventFormData,
        {
          auth: userDetails.access_token,
          content_type: ContentTypeEnum.MULTIPART_FORM,
        }
      );
      if (res?.code == "00") {
        toast("Your event has been published", {
          type: ErrorSeverityEnum.success,
        });
        navigate("/event-admin");
      } else {
        toast(errorMessage, {
          type: ErrorSeverityEnum.error,
        });
      }
    }
  };

  const getUserData = async () => {
    const storedData = localStorage.getItem("userDetails");
    if (storedData) {
      try {
        const userDetailsObject = JSON.parse(storedData);

        setUserDetails(userDetailsObject);
      } catch (error) {
        localStorage.clear();
        EventRegister.emit(EventsEnum.LoginUser, true);
      }
    }
  };

  const initFunction = async () => {
    const pathname = window.location.pathname;
    const searchParams = new URLSearchParams(window.location.search);

    // If URL is in format "/e/:purl"
    if (pathname.startsWith("/e/")) {
      const purl = pathname.split("/")[2];
      const res = await apiGetRequest(`${urls.events}${purl}`);
      if (res?.data != null) {
        setEventDetails(res.data);
        setEventPoster(res.data.poster);
        const imageList = res.data.image_sliders.map((x: any) => x.image_link);
        setImages(imageList);
        setEventTickets(res.data.tickets ?? []);
        setEventDate(res.data.event_date);
        setEndDate(res.data.end_date);
        setTicketList((res.data.tickets ?? []).map(() => 0));
        setEventTime(res.data.end_date);
        setCurrency(res.data.currency);
        if ((res.data.custom_fields ?? []).length > 0) {
          setCustomFields(res.data.custom_fields);
        }

        if ((res.data.tickets ?? []).length > 0) {
          setSelectedTicket(res.data.tickets[0].id);
        }
      }
      // console.log("res", res);
    } else if (searchParams.has("eventId")) {
      const eventId = searchParams.get("eventId");
      const promoCode = searchParams.get("promoCode");

      // Fetch event details using eventId
      const res = await apiGetRequest(`${urls.events}${eventId}`);
      if (res?.data != null) {
        setEventDetails(res.data);
        setEventPoster(res.data.poster);
        setEventTickets(res.data.tickets ?? []);
        setEventDate(res.data.event_date);
        setEndDate(res.data.end_date);
        setTicketList((res.data.tickets ?? []).map(() => 0));
        setEventTime(res.data.end_date);
        setCurrency(res.data.currency);
        if ((res.data.custom_fields ?? []).length > 0) {
          setCustomFields(res.data.custom_fields);
        }

        if ((res.data.tickets ?? []).length > 0) {
          setSelectedTicket(res.data.tickets[0].id);
        }
      }
      // console.log("res1", res);
    }

    if (searchParams.has("promCode")) {
      const promCode = searchParams.get("promCode");
      setPromoCode(promCode ?? "");
      // applyPromoCodeFunct(promCode ?? "", userDetailsObject);
    }
  };

  useEffect(() => {
    initFunction();
    getAd();

    getUserData();

    setSubscribedEvent(
      EventRegister.addEventListener(
        EventsEnum.CheckLoginStatus,
        (data: boolean) => {
          getAd();
        }
      )
    );
    return () => {
      EventRegister.removeEventListener(subscribedEvent);
    };
  }, []);

  const resetForm = () => {
    setUserWallets([]);
    setOpen(false);
    setUserDetails(null);
    setQasaPin("");
    setPaymentMode("");
    setValue(1);
    setRetryCount(1);
    setPromoCodeUsed(false);
    setChecked(false);
    setDiscountedAmount("");
    setPromoCode("");
    setEmail("");
    setCheckout(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheckout = async () => {
    // check if the ticket stuff is done.
    // Here, I will send the list of the tickets purchased. Both names and amount.
    const storedData = localStorage.getItem("userDetails");
    if (storedData) {
      try {
        const userDetailsObject = JSON.parse(storedData);
        const { country, ...rest } = userDetailsObject;

        setUserDetails(userDetailsObject);
        const res = await apiGetRequest(`${urls.wallet}`, {
          auth: userDetailsObject?.access_token,
          content_type: ContentTypeEnum.APPLICATION_JSON,
        });

        if (res?.code === "00") {
          setUserWallets(res.data);
          handleOpen();

          navigate("/checkout", {
            state: {
              purchasedTickets: purchasedTicket,
              eventDetailz: eventDetails,
              ticket_subTotal: actualAmount,
              ticket_currency: currency,
              ticket_fees: fees,
              user_phone: userDetailsObject.phone,
              user_country: userDetailsObject.country,
              user_wallet: res.data[0],
              isPromoCodeUsed: promoCodeUsed,
              tickets_data: ticketData,
              ticket_promo_code: promoCode,
              user_data: userDetailsObject,
            },
          });
        }
      } catch (error) {
        localStorage.clear();
        EventRegister.emit(EventsEnum.LoginUser, true);
      }
    } else {
      // EventRegister.emit(EventsEnum.LoginUser, true);
      navigate("/checkout", {
        state: {
          purchasedTickets: purchasedTicket,
          eventDetailz: eventDetails,
          ticket_subTotal: actualAmount,
          ticket_currency: currency,
          ticket_fees: fees,
          user_phone: null,
          user_country: null,
          user_wallet: null,
          isPromoCodeUsed: promoCodeUsed,
          tickets_data: ticketData,
          ticket_promo_code: promoCode,
          user_data: null,
        },
      });
    }
    // setCheckout(true);
  };

  function capitalizeText(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  const applyPromoCodeFunct = async () => {
    if (ticketData.length > 0 && promoCode !== "") {
      const promoCodeData = {
        promo_code: promoCode,
        tickets_data: ticketData,
      };
      const resp = await apiPostRequest(
        "/events/apply_promo_code",
        promoCodeData,
        {
          auth: userDetails?.access_token,
          content_type: ContentTypeEnum.APPLICATION_JSON,
        }
      );

      if (resp.code === "00") {
        // toast(resp.msg ?? "Action Successful", {
        //   type: ErrorSeverityEnum.success,
        // });actualAmount fees setActualAmount setTicketsFee
        setDiscountedAmount(`${resp.data.discounted_amount}`);
        setPromoCodeUsed(true);
        if (resp.data.discount_value === 100) {
          setTicketsFee(0);
          setActualAmount(resp.data.discounted_amount);
        } else {
          setActualAmount(resp.data.discounted_amount - fees);
        }
        setIsPromoCodeValid("valid");
        setSubTotal(resp.data.discounted_amount);
      } else {
        setIsPromoCodeValid("invalid");
        // toast(resp.msg ?? "Action failed", {
        //   type: ErrorSeverityEnum.error,
        // });
      }
    }
  };

  function handleTicketCount(
    index: number,
    value: string | number,
    ticket: Ticket
  ): void {
    const updatedCounts = [...ticketList];
    let newCount: number;

    // Handle when value is passed from the input box (string) or buttons (number)
    if (typeof value === "string") {
      newCount = parseInt(value, 10);
    } else {
      newCount = value;
    }

    // Ensure the count is within the valid range (0 to ticket.quantity)
    if (newCount < 0) newCount = 0;
    if (newCount > Math.min(ticket.quantity, 10))
      newCount = Math.min(ticket.quantity, 10);

    updatedCounts[index] = newCount;

    const ticketInfo: TicketInfo = {
      ticket_id: ticket.id,
      quantity: newCount,
    };

    const updatedSoldOutTickets = new Set(soldOutTickets);
    if (newCount >= ticket.quantity) {
      updatedSoldOutTickets.add(ticket.id);
    } else {
      updatedSoldOutTickets.delete(ticket.id);
    }

    setSoldOutTickets(updatedSoldOutTickets);

    const areAllTicketsSoldOut = eventTickets.every(
      (ticket) =>
        updatedSoldOutTickets.has(ticket.id) ||
        updatedCounts[eventTickets.indexOf(ticket)] >= ticket.quantity
    );

    setAllTicketsSoldOut(areAllTicketsSoldOut);

    const ticketPurchased: PurchasedTicket = {
      ticket_id: ticket.id,
      ticket_name: ticket.ticket_name,
      ticket_price: ticket.price,
      ticket_currency: ticket.currency,
      ticket_quantity: ticket.quantity,
    };

    const existingTicketIndex = ticketData.findIndex(
      (data) => data.ticket_id === ticketInfo.ticket_id
    );

    if (existingTicketIndex !== -1) {
      const updatedTicketData = [...ticketData];
      updatedTicketData[existingTicketIndex] = ticketInfo;
      setTicketData(updatedTicketData);
    } else {
      setTicketData((prevTicketData) => [...prevTicketData, ticketInfo]);
    }

    const existingPurchasedTicketIndex = purchasedTicket.findIndex(
      (data) => data.ticket_id === ticketPurchased.ticket_id
    );

    if (existingPurchasedTicketIndex !== -1) {
      const updatedPurchasedTicket = [...purchasedTicket];
      updatedPurchasedTicket[existingPurchasedTicketIndex].ticket_price +=
        ticketPurchased.ticket_price;
      setPurchasedTicket(updatedPurchasedTicket);
    } else {
      setPurchasedTicket((prevPurchasedTicket) => [
        ...prevPurchasedTicket,
        ticketPurchased,
      ]);
    }

    const hasTickets = updatedCounts.some((count) => count > 0);

    const newSubTotal = updatedCounts.reduce((total, count, i) => {
      return total + count * eventTickets[i].price;
    }, 0);

    const newFeeTotal = updatedCounts.reduce((total, count, i) => {
      return total + count * eventTickets[i].fee;
    }, 0);

    const actualAmount = newSubTotal + newFeeTotal;

    setActualAmount(newSubTotal);
    setTicketsFee(newFeeTotal);
    setTicketCounts(hasTickets);
    setTicketList(updatedCounts);
    setSubTotal(actualAmount);
  }

  function handleFieldChange(id: number, value: string) {
    setFieldValues((prevValues) =>
      prevValues.map((field) => (field.id === id ? { ...field, value } : field))
    );
  }

  const hasExpiredTickets = () => {
    return eventTickets.some(
      (ticket, index) => ticket.expired && ticketList[index] > 0
    );
  };

  const isOverPurchasing = () => {
    return eventTickets.some(
      (ticket, index) => ticketList[index] > ticket.quantity
    );
  };

  const hasSelectedTickets = () => {
    return ticketList.some((count) => count > 0);
  };

  const handleDotClick = (index: number) => {
    setCurrentImageIndex(index);
  };

  return (
    <>
      <MetaTags
        title={eventDetails?.event_name}
        purl={eventDetails?.purl}
        description={eventDetails?.description}
        image={eventDetails?.poster}
      />
      <Modal
        open={showPaymentHubtel}
        onClose={() => {
          setShowHubtelPayment(false);
          resetForm();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, backgroundColor: "#f7f8fb" }}>
          {eventDetails && (
            // && userWallets.length > 0
            <Row mainAxisAlignment="space-between">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {`Purchase "${eventDetails.event_name}" ticket)`}
              </Typography>
              <Container
                onClick={() => {
                  handleClose();
                }}
              >
                <CloseIcon />
              </Container>
            </Row>
          )}
          <iframe
            title="urlRenderer"
            src={hubtelUrlToRender}
            width="100%"
            height="500px"
            frameBorder="0"
          ></iframe>
        </Box>
      </Modal>
      <>
        <FullWidthColumn>
          {eventDetails && (
            <section className="event-details-section">
              <div className="event-image-flyer">
                <Box
                  // component="img"
                  sx={{
                    height: "50vh",
                    width: "100%",
                    objectFit: "cover",
                  }}
                >
                  <VaryingWidthImage
                    imageUrl={images[currentImageIndex] || eventDetails.poster}
                    blurImageUrl={
                      images[currentImageIndex] ?? eventDetails.poster
                    }
                    imageList={images}
                    type="details_page"
                    disableSlider={false}
                    currentIndex={currentImageIndex}
                    onIndexChange={setCurrentImageIndex}
                  />
                </Box>
                <DotsContainer>
                  {images.map((_, index) => (
                    <Dot
                      key={index}
                      isActive={index === currentImageIndex}
                      onClick={() => handleDotClick(index)}
                    />
                  ))}
                </DotsContainer>
              </div>
              <div className="flex-container">
                <div className="flex-item">
                  <div className="events">
                    <HeaderGreenTopBar />
                    <h2 className="event-content-header">EVENT INFORMATION</h2>
                    <p className="event-content-sub-header">
                      Event Name<br></br>
                      <span className="event-content-sub-info">
                        {" "}
                        {eventDetails.event_name}
                      </span>
                    </p>
                    <p className="event-content-sub-header">
                      Event Date<br></br>
                      <span className="event-content-sub-info">
                        {" "}
                        {
                          convertDateTimeToUsersTimezone(event_date, timeZone)
                            .date
                        }
                      </span>
                    </p>
                    <p className="event-content-sub-header">
                      Event Time<br></br>
                      <span className="event-content-sub-info">
                        {" "}
                        {
                          convertDateTimeToUsersTimezone(event_date, timeZone)
                            .time
                        }
                      </span>
                    </p>
                    <p className="event-content-sub-header">
                      Event Address<br></br>
                      <span className="event-content-sub-info">
                        {eventDetails.venue}
                      </span>
                    </p>
                    {eventDetails.description && (
                      <p className="event-content-sub-header">
                        Event Description<br></br>
                        <span className="event-content-sub-info">
                          {eventDetails.description}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
                <div
                  className="vertical-separator"
                  ref={ticketSectionRef}
                ></div>
                <div className="flex-item">
                  <div className="events">
                    <HeaderGreenTopBar />
                    <h2 className="event-content-header">EVENT TICKETS</h2>

                    {eventTickets.map(
                      (ticket, index) =>
                        ticket.quantity >= 0 && (
                          <div
                            className="event-pricing-content"
                            key={ticket.id}
                          >
                            <div className="pricing-info">
                              {ticket.ticket_name}
                              {!ticket.expired && (
                                <>
                                  {ticketList[index] >= ticket.quantity && (
                                    <span
                                      style={{
                                        color: "#EB352C",
                                        fontWeight: "bold",
                                        display: "block",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {ticket.quantity === 0 ? (
                                        <span
                                          style={{
                                            color: "#EB352C",
                                            fontWeight: "bold",
                                            display: "block",
                                            marginTop: "5px",
                                          }}
                                        >
                                          SOLD OUT!
                                          <WaitlistButton
                                            eventId={eventDetails.id}
                                            ticketId={ticket.id}
                                            isUserLoggedIn={true}
                                            onSuccess={() => {}}
                                          />
                                        </span>
                                      ) : (
                                        "Last ticket!"
                                      )}

                                    </span>
                                  )}
                                  {ticketList[index] < ticket.quantity &&
                                    ticket.quantity <= 10 && (
                                      <span
                                        style={{
                                          color: "red",
                                          fontWeight: "bold",
                                          display: "block",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {ticket.quantity - ticketList[index]}{" "}
                                        tickets left!
                                      </span>
                                    )}
                                </>
                              )}
                              <br />
                              <span className="event-content-sub-info">
                                {ticket.price !== 0 ? ticket.currency : ""}
                                {ticket.price !== 0
                                  ? ticket.price
                                  : "Free"}{" "}
                                &nbsp; + &nbsp;
                                {ticket.fee !== 0 ? ticket.currency : ""}
                                {ticket.fee !== 0
                                  ? `${ticket.fee} Fee`
                                  : "0"}{" "}
                                &nbsp;
                              </span>
                            </div>

                            <div>
                              {ticket.quantity > 0 && !ticket.expired && (
                                <div>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleTicketCount(
                                        index,
                                        Math.max(ticketList[index] - 1, 0),
                                        ticket
                                      )
                                    }
                                    disabled={ticketList[index] <= 0}
                                    className="ticket-control-btn"
                                  >
                                    <img src={Minus} alt="subtraction icon" />
                                  </button>
                                  {ticket.ticket_name ===
                                    "Earlybird - Expired" ||
                                  ticket.expired ||
                                  ticketList[index] < 0
                                    ? " "
                                    : ticketList[index]}
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleTicketCount(
                                        index,
                                        Math.min(ticketList[index] + 1, 10),
                                        ticket
                                      )
                                    }
                                    disabled={ticketList[index] >= 10}
                                    className="ticket-control-btn"
                                  >
                                    <img src={Add} alt="Addition Icon" />
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        )
                    )}

                    {eventDetails.display_status !== DisplayStatusEnum.draft &&
                      eventDetails.has_promo_codes && (
                        <div className="promo-code">
                          <PromoCode
                            sx={{
                              "& fieldset": { border: "none" },
                              width: "80%",
                              maxWidth: "250px",
                            }}
                            InputProps={{
                              inputProps: {
                                style: {
                                  textAlign: "left",
                                  borderRadius: "100rem",
                                  background: "rgba(255, 255, 255, 0.694)",
                                  height: "20px",
                                },
                              },
                              endAdornment: (
                                <InputAdornment position="end">
                                  {isPromoCodeValid !== null &&
                                    (isPromoCodeValid === "valid" ? (
                                      <CheckIcon style={{ color: "green" }} />
                                    ) : isPromoCodeValid === "invalid" ? (
                                      <CloseIcon style={{ color: "red" }} />
                                    ) : (
                                      ""
                                    ))}
                                </InputAdornment>
                              ),
                            }}
                            onBlur={applyPromoCodeFunct}
                            onChange={(e) => {
                              setPromoCode(e.target.value);
                            }}
                            margin="normal"
                            id="promoCode"
                            label="Promo Code"
                            name="promoCode"
                            // error={!isPromoCodeValid}
                            // helperText={!isValid ? "Invalid promo code" : ""}
                          />
                        </div>
                      )}

                    {customFields.map((custom_field, index) => (
                      <>
                        <div className="custom_title">
                          <strong>
                            {" "}
                            {capitalizeText(custom_field.field_name)}
                          </strong>
                        </div>
                        <StyledTextField
                          sx={{
                            "& fieldset": { border: "none" },
                            width: "100%",
                            maxWidth: "250px",
                            marginTop: "0px",
                            marginBottom: "5px",
                          }}
                          InputProps={{
                            inputProps: {
                              style: {
                                textAlign: "left",
                                borderRadius: "100rem",
                                background: "rgba(255, 255, 255, 0.694)",
                                height: "20px",
                              },
                            },
                          }}
                          onChange={(e) => {
                            handleFieldChange(custom_field.id, e.target.value);
                          }}
                          margin="normal"
                          label={custom_field.field_name}
                          name={custom_field.field_name}
                        />
                      </>
                    ))}

                    <div className="amount-total">
                      <strong>Sub Total</strong>
                      <br></br>
                      {currency}
                      {subTotal.toFixed(2)}
                    </div>

                    {/* {!userDetails && (
                      <div style={{ maxWidth: "300px" }}>
                        <Login
                          isCheckoutPage
                          setOtpCode={(val) => {}}
                          setViewOtp={(val: boolean) => {}}
                          disableButton={
                            !checkout && !ticketCounts && !allTicketsSoldOut
                          }
                          handleSendOtp={async (
                            mobile: string,
                            country: string,
                            firstName: string,
                            lastName: string
                          ) => {
                            const resp = await apiPostRequest("/auth/login", {
                              type: 4,
                              phone: mobile,
                              country: country,
                              avatar:
                                "https://qasaio.nyc3.digitaloceanspaces.com/default/single_user.png",
                              name: `${firstName} ${lastName}`,
                              open_id: "Test1234",
                            });
                            console.log(resp);
                            if (resp.code == "00") {
                              localStorage.setItem(
                                "userDetails",
                                JSON.stringify(resp.data)
                              );
                              EventRegister.emit(
                                EventsEnum.CheckLoginStatus,
                                true
                              );
                              getUserData();

                              setTimeout(() => {
                                handleCheckout();
                              }, 2000);
                            }
                          }}
                        />
                      </div>
                    )} */}

                    {eventDetails.display_status != DisplayStatusEnum.draft && (
                      <div className="checkout-button-wrapper">
                        {hasSelectedTickets() &&
                        !hasExpiredTickets() &&
                        !isOverPurchasing() ? (
                          <button
                            type="submit"
                            className="event-submit-btn"
                            onClick={handleCheckout}
                          >
                            Checkout
                          </button>
                        ) : (
                          <button
                            type="submit"
                            // disabled
                            className="event-submit-btn-disabled"
                            onClick={scrollToTicketSection}
                          >
                            Checkout
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          )}
          {eventDetails?.display_status == DisplayStatusEnum.draft && (
            <Row spacing={40} sx={{ marginBottom: "40rem", width: "100%" }}>
              <Button
                variant="outlined"
                sx={{
                  width: "100%",
                  marginTop: "5rem",
                  height: "48px",
                  borderRadius: "100rem",
                  borderColor: "#0CAF6D",
                  color: "#0CAF6D",
                }}
                // disabled={!allValuesFilled()}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </Button>
              {
                <Button
                  variant="contained"
                  sx={{
                    width: "100%",
                    marginTop: "5rem",
                    height: "100%",
                    borderRadius: "100rem",
                    backgroundColor: "#0CAF6D",
                  }}
                  onClick={() => handlePublish()}
                >
                  Publish
                </Button>
              }
            </Row>
          )}
        </FullWidthColumn>
        <div style={{ marginTop: "3rem", width: "100%", height: "100%" }}>
          {adData && <BannerAd adData={adData} />}
        </div>
      </>
    </>
  );
}

export default EventDetails;

const PromoCode = styled(TextField)({
  "& .MuiInputLabel-root": {
    textAlign: "left",
  },
  "& .MuiInputLabel-shrink": {
    margin: "0 auto",
    position: "absolute",
    left: "0",
    top: "-3px",
    width: "150px",
    background: "none",
  },
  "& .MuiOutlinedInput-root.Mui-focused": {
    "& legend ": {
      display: "none",
    },
  },
});

const Dot = styled("div")<DotProps>(({ isActive }) => ({
  width: "12px",
  height: "12px",
  margin: "0 5px",
  borderRadius: "50%",
  backgroundColor: isActive ? "#57B87C" : "#D3D3D3", // Match the green and light gray colors
  cursor: "pointer",
}));

const DotsContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  marginTop: "10px",
});
