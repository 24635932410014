// download.tsx
import React, { useEffect, useState } from "react";
import "./css/download.css";
import Google from "../../_shared/assets/microsite/google-play.png";
import MicroNavbar from "./MicroHeader";
import MicroFooter from "./MicroFooter";

// Type declarations to fix TypeScript errors
declare global {
  interface Window {
    opera?: any;
    MSStream?: any;
  }
}

interface NavigatorWithVendor extends Navigator {
  vendor: string;
}

const MicroDownloadPage = () => {
  const [os, setOs] = useState("unknown");

  useEffect(() => {
    // Type assertions to handle userAgent checks
    const userAgent =
      window.navigator.userAgent ||
      (window.navigator as NavigatorWithVendor).vendor ||
      window.opera;

    if (/android/i.test(userAgent)) {
      setOs("android");
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setOs("ios");
    }
  }, []);

  return (
    <div className="download-page">
      <MicroNavbar />
      <div className="download-container">
        <div className="download-content">
          <h1>Download Qasa today!</h1>
          <p>
            Unlock seamless, real-time communication with QASA. Send texts,
            share media, and even make voice/video calls online all in a secured
            app.
          </p>

          <div className="download-buttons">
            {os !== "android" && (
              <a
                href="https://apps.apple.com/us/app/qasa-app/id6464432973"
                className="store-button"
              >
                <img
                  src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/en-us?size=250x83&releaseDate=1586908800"
                  alt="Download on the App Store"
                  title="Download on the App Store"
                />
              </a>
            )}

            {os !== "ios" && (
              <a
                href="https://play.google.com/store/apps/details?id=com.dubtel.qasa&pcampaignid=web_share"
                className="store-button"
                title="Download Qasa on Google Playstore"
              >
                <img src={Google} alt="Download Qasa on Google Playstore" />
              </a>
            )}
          </div>
        </div>
      </div>
      <MicroFooter />
    </div>
  );
};

export default MicroDownloadPage;
