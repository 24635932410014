import React, { useState, useEffect } from "react";
import "./css/faq.css";
import QasaHome from "../../_shared/assets/microsite/qasa_home.jpg";
import ContactCountry from "../../_shared/assets/microsite/add-contact-country-code-prefill.jpg";
import ContactList from "../../_shared/assets/microsite/contact-list.jpg";
import MicroNavbar from "./MicroHeader";
import MicroFooter from "./MicroFooter";


const MicroFAQ = () => {
  const [activeAccordion, setActiveAccordion] = useState<string | null>(null);
  const [focusedAccordion, setFocusedAccordion] = useState<string | null>(null);

  const toggleAccordion = (id: string) => {
    if (activeAccordion === id) {
      setActiveAccordion(null);
      // Don't remove focus when closing
    } else {
      setActiveAccordion(id);
      setFocusedAccordion(id);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest('.accordion-button')) {
        setFocusedAccordion(null);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="page-wrapper">
      <MicroNavbar />

      <div className="faq-container">
        <div className="header-section">
          {/* <svg className="faq-icon" style={{ color: "#6610f2" }} width="100" height="100">
            <use xlinkHref="#faqs" />
          </svg> */}
          <h1 className="main-title">Frequently Asked Questions (FAQs)</h1>
          <p className="subtitle">
            We know you have got questions, well to be honest, we have got all the answers.
            Let's satisfy all your curiousities
            <img
              src="https://uxwing.com/wp-content/themes/uxwing/download/emoji-emoticon/smiley-icon.png"
              alt="smiley"
              className="smiley-icon"
            />
          </p>
        </div>

        <div className="accordion">
          {/* What is QASA? */}
          <div className={`accordion-item ${activeAccordion === "one" ? "expanded" : ""}`}>
            <h2 className="accordion-header">
              <button
                className={`accordion-button ${activeAccordion === "one" ? "active" : ""} ${focusedAccordion === "one" ? "focus" : ""}`}
                onClick={() => toggleAccordion("one")}
              >
                <h4>What is QASA?</h4>
              </button>
            </h2>
            <div className={`accordion-content ${activeAccordion === "one" ? "show" : ""}`}>
              <div className="accordion-body">
                <p>
                  QASA is a software application that allows users to send and
                  receive text messages, multimedia messages (such as photos and
                  videos), and sometimes voice or video calls over the internet.
                  These apps are designed to facilitate real-time communication
                  between individuals or groups of people.
                </p>
                <p>
                  <strong>
                    Key features and functionalities found in QASA include:
                  </strong>
                </p>
                <p>
                  <strong>Text Messaging:</strong> Users can send text-based
                  messages to others in real-time, creating a chat conversation.
                </p>
                <p>
                  <strong>Multimedia Sharing:</strong> Users can share photos,
                  videos, audio clips, documents, and other types of multimedia
                  content within chat conversations.
                </p>
                <p>
                  <strong>Voice and Video Calls:</strong> Many messaging apps
                  include voice and video calling capabilities, allowing users
                  to have voice or video conversations within the app.
                </p>
                <p>
                  <strong>Group Chats:</strong> Users can create group chats to
                  communicate with multiple people simultaneously. Group chats
                  are commonly used for family, friends, or work-related
                  discussions.
                </p>
                <p>
                  <strong>Emojis and Stickers:</strong> Messaging apps often
                  include a wide range of emojis, stickers, and GIFs to add fun
                  and expressiveness to conversations.
                </p>
                <p>
                  <strong>Read Receipts:</strong> Some apps provide read
                  receipts, indicating when a message has been seen or read by
                  the recipient.
                </p>
                <p>
                  <strong>Encryption:</strong> Many modern messaging apps
                  prioritize security and offer end-to-end encryption, which
                  ensures that only the sender and recipient can read the
                  messages.
                </p>
                <p>
                  <strong>Status Updates:</strong> Users can post status updates
                  to let their contacts know their current activity, mood, or
                  location.
                </p>
                <p>
                  <strong>Notifications:</strong> Messaging apps send
                  notifications to users when they receive new messages or
                  calls, helping ensure timely communication.
                </p>
                <p>
                  <strong>Integration with Other Apps:</strong> Some messaging
                  apps integrate with other applications and services, allowing
                  users to share content from third-party apps directly in their
                  conversations.
                </p>
                <p>
                  <strong>Voice Messages:</strong> Users can send short voice
                  messages in addition to text-based messages.
                </p>
                <p>
                  QASA will become an integral part of modern communication,
                  enabling people to stay in touch with friends, family, and
                  colleagues, regardless of geographical distances, and
                  facilitating both personal and professional interactions.
                </p>
              </div>
            </div>
          </div>

          {/* How easy is it to Signup? */}
          <div className={`accordion-item ${activeAccordion === "two" ? "expanded" : ""}`}>
            <h2 className="accordion-header">
              <button
                className={`accordion-button ${activeAccordion === "two" ? "active" : ""} ${focusedAccordion === "two" ? "focus" : ""}`}
                onClick={() => toggleAccordion("two")}
              >
                <h4>How easy is it to Signup?</h4>
              </button>
            </h2>
            <div className={`accordion-content ${activeAccordion === "two" ? "show" : ""}`}>
              <div className="accordion-body">
                <div className="signup-content">
                  <div className="signup-text">
                    QASA is designed with you in mind; to signup you simply
                    launch the Qasa app and enter your Name, Phone Number and
                    Country. When you click the Login button you will be
                    presented with a One-Time-Password for verification.
                  </div>
                  <div className="signup-image">
                    <img
                      src={QasaHome}
                      alt="Qasa App"
                      className="content-image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* How do I add a Contact? */}
          <div className={`accordion-item ${activeAccordion === "three" ? "expanded" : ""}`}>
            <h2 className="accordion-header">
              <button
                className={`accordion-button ${activeAccordion === "three" ? "active" : ""} ${focusedAccordion === "three" ? "focus" : ""}`}
                onClick={() => toggleAccordion("three")}
              >
                <h4>How do I add a Contact?</h4>
              </button>
            </h2>
            <div className={`accordion-content ${activeAccordion === "three" ? "show" : ""}`}>
              <div className="accordion-body">
                Adding Contacts in QASA is very easy; from the Contact list
                screen you can click the plus icon on the bottom right which
                will display the "Add Contact" screen. On the Add Contact screen
                you can add your new contacts details which include; First Name,
                Last Name and Phone Number.
                <div className="contact-images">
                  <img
                    src={ContactCountry}
                    alt="Add Contact"
                    className="contact-image"
                  />
                  <img
                    src={ContactList}
                    alt="Contact List"
                    className="contact-image"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Is my data safe? */}
          <div className={`accordion-item ${activeAccordion === "four" ? "expanded" : ""}`}>
            <h2 className="accordion-header">
              <button
                className={`accordion-button ${activeAccordion === "four" ? "active" : ""} ${focusedAccordion === "four" ? "focus" : ""}`}
                onClick={() => toggleAccordion("four")}
              >
                <h4>Is my data safe?</h4>
              </button>
            </h2>
            <div className={`accordion-content ${activeAccordion === "four" ? "show" : ""}`}>
              <div className="accordion-body">
                Qasa is the most secured messaging app on the planet and even in
                the galaxy. Your data is encrypted when your message is sent and
                the only people who can receive or read your message are your
                intended recipients. Unlike other popular messaging apps Qasa
                does not sell your information and messages to marking
                companies.
              </div>
            </div>
          </div>

          {/* How do I install the Qasa App? */}
          <div className={`accordion-item ${activeAccordion === "five" ? "expanded" : ""}`}>
            <h2 className="accordion-header">
              <button
                className={`accordion-button ${activeAccordion === "five" ? "active" : ""} ${focusedAccordion === "five" ? "focus" : ""}`}
                onClick={() => toggleAccordion("five")}
              >
                <h4>How do I install the Qasa App?</h4>
              </button>
            </h2>
            <div className={`accordion-content ${activeAccordion === "five" ? "show" : ""}`}>
              <div className="accordion-body">
                <p>To install Qasa, please follow these steps:</p>
                <ol>
                  <li>
                    Visit our official website at{" "}
                    <a href="https://www.qasa.me">www.qasa.me</a>.
                  </li>
                  <li>
                    Choose the appropriate version of the app for your device
                    (iOS or Android).
                  </li>
                  <li>
                    Click on the download link icon provided for your device.
                  </li>
                  <li>
                    Follow the on-screen instructions to complete the download
                    and installation process.
                  </li>
                  <li>
                    After download, you can refer to the signup instruction
                    above to help you set up your Qasa account
                  </li>
                </ol>
                <p>
                  Additionally, you can go to the{" "}
                  <a href="/pages/downloads">downloads page</a> and you would be
                  redirected to the appropriate store. Alternatively, search for
                  the term <strong>Qasa</strong> on Google or Apple appstore and
                  you should see the Qasa app for your download
                </p>
                <p>
                  If you encounter any issues or have further questions, feel
                  free to <a href="/pages/contact">contact us</a> for
                  assistance.
                </p>
              </div>
            </div>
          </div>

          {/* How to Find my Purchased Ticket(s) */}
          <div className={`accordion-item ${activeAccordion === "six" ? "expanded" : ""}`}>
            <h2 className="accordion-header">
              <button
                className={`accordion-button ${activeAccordion === "six" ? "active" : ""} ${focusedAccordion === "six" ? "focus" : ""}`}
                onClick={() => toggleAccordion("six")}
              >
                <h4>How to Find my Purchased Ticket(s)</h4>
              </button>
            </h2>
            <div className={`accordion-content ${activeAccordion === "six" ? "show" : ""}`}>
              <div className="accordion-body">
                <p>You can view your tickets on Web and on Mobile.</p>
                <p>
                  After purchasing tickets on the web (
                  <a href="https://events.qasa.me">https://events.qasa.me</a>)
                  or through the mobile app, follow these steps to access your
                  tickets:
                </p>
                <ol>
                  <li>
                    <strong>Confirmation SMS:</strong> Upon completing your
                    ticket purchase, you will receive a confirmation SMS on your
                    mobile number. This SMS contains a link to your digital
                    ticket. Open this link to view your digital tickets.
                    <ul>
                      <li>
                        Your digital ticket includes essential information such
                        as the event name, date, the Qasa logo, a QR code for
                        your ticket, location (indicated by a map icon), ticket
                        receipt details, and the event banner.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>Viewing on Mobile App:</strong>
                    <ol type="a">
                      <li>
                        <strong>Download the Qasa App:</strong> Ensure that the
                        Qasa app is downloaded on your mobile device. Head over
                        to the{" "}
                        <a href="/pages/downloads" title="Download Qasa">
                          downloads page
                        </a>{" "}
                        to download and install the app.
                      </li>
                      <li>
                        <strong>Open the Qasa App:</strong> Launch the Qasa app
                        on your mobile device.
                      </li>
                      <li>
                        <strong>Navigate to Events:</strong> Tap on "Events"
                        located on the top right next to Calls.
                      </li>
                      <li>
                        <strong>Access Tickets:</strong> On the Events screen,
                        click on the first icon from the top on the bottom right
                        of the screen to navigate to your tickets screen.
                      </li>
                      <li>
                        <strong>View Ticket Details:</strong> Select any ticket
                        to view its details.
                      </li>
                    </ol>
                  </li>
                </ol>
                <p>
                  By following these steps, you can easily access and view your
                  tickets either through the confirmation SMS link or the Qasa
                  mobile app.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MicroFooter   />
    </div>
  );
};

export default MicroFAQ;