import "./css/privacyPolicy.css";
import MicroFooter from "./MicroFooter";
import MicroNavbar from "./MicroHeader";

const MicroPrivacyPolicy = () => {
  return (
    <>
      <MicroNavbar />

      <main className="main-container">
        <h1 className="main-title">Privacy Policy for Qasa</h1>

        <p className="intro-text">
          We know you have got questions, well to be honest, we have got all the
          answers. Delve in the curious minds of fellow Qasa-"ites"
        </p>

        <section>
          <h2 className="section-title">Introduction</h2>
          <p className="section-text">
            Welcome to Qasa. We take privacy and data security very seriously,
            especially when it comes to data you provide to us. This policy
            explains how we collect and use the information you provide to us,
            along with that which we collect automatically when you use our
            Services. We welcome feedback about this policy or any of our
            practices. You can reach us via email at{" "}
            <a href="mailto:support@qasa.me">support@qasa.me</a>, or at the
            appropriate addresses which we have listed below.
          </p>
        </section>

        <section>
          <h2 className="section-title">Who We Are, And How To Contact Us</h2>
          <p className="section-text">
            We are Qasa. You can reach us via email at{" "}
            <a href="mailto:support@qasa.me">support@qasa.me</a>.
          </p>
        </section>

        <section>
          <h2 className="section-title">
            The Information Qasa Collects About You
          </h2>
          <p className="section-text">
            Qasa provides a number of services (collectively the "Services").
            When you use these Services, Qasa receives data from you in a
            variety of ways. When you open an account or send chat communication
            with us, you send us certain details. These include: Email address,
            phone number, password, store name, and similar account details. For
            paying customers, we also collect billing details such as credit
            card information and a physical address. If you apply for a Qasa
            account, you may also supply us with your bank account details.
            Additionally, as you use our Services, we may collect other data
            automatically. Here is an explanation of such other data we may
            collect: Your usage of our Services including Register activations
            and visits to BackOffice. Usage details when you log in, such as
            your Internet Protocol (IP) address, and details about your device
            including your operating system type and version, and your screen
            size. As with most online Services, we also collect log data. This
            log data may include your Internet Protocol (IP) address, the
            address of the web page visited before using the Services, browser
            type and settings, the date and time the Services were used,
            information about browser configuration and plugins, language
            preferences and cookie data. We use cookies and HTML 5 Local Storage
            to keep track of your local computer's settings such as which
            account you have logged into. Cookies are small pieces of data that
            sites and services can set on your mobile app or device that can be
            read on future visits. HTML 5 Local Storage provides a similar
            method to store information on your device. We use third-party
            analytics tools such as Google Analytics and New Relic on our
            website and app. These may employ cookies to collect certain
            information concerning your use of our Services. Do Not Track
            ("DNT") is a browser feature available in most modern browsers. The
            feature allows a browser to automatically signal a user's preference
            regarding online tracking. Qasa's apps do not yet have the ability
            to honor this signal.
          </p>
        </section>

        <section>
          <h2 className="section-title">How We Use Your Information</h2>
          <p className="section-text">
            We use the information that we collect from you to fulfill a
            contract with you and provide you with our Services. For example, if
            you place an order with us, we use your information to process and
            deliver that order. We use analytics to understand how our Services
            are used. These are required to provide us with the ability to
            operate, support, and continue building our Services. This research
            may be compiled and analyzed on an aggregate basis, and we may share
            this aggregate data with our affiliates, agents, and business
            partners. We may also disclose aggregated user statistics in order
            to describe our services to current and prospective business
            partners, and to other third parties for other lawful purposes.
          </p>
        </section>

        <section>
          <h2 className="section-title">
            Data Sharing, And Other Recipients Of Your Information
          </h2>
          <p className="section-text">
            Qasa does not sell or pass on any Personally Identifiable
            Information (as defined below) to any other party except when
            fulfilling our contractual obligation to provide you with our
            Services. The term Personally Identifiable Information means
            individually identifiable information about an individual consumer
            collected online by the operator from that individual and maintained
            by the operator in an accessible form, including any of the
            following: (1) A first and last name. (2) A home or other physical
            address, including street name and name of a city or town. (3) An
            e-mail address. (4) A telephone number. (5) A social security
            number. (6) Any other identifier that permits the physical or online
            contacting of a specific individual. (7) Information concerning a
            user that the Web site or online service collects online from the
            user and maintains in personally identifiable form in combination
            with an identifier described in this section. For example, you can
            optionally enable features that will require Qasa to send data to a
            third party to fulfill these Services. Examples of these features
            are MailChimp, Twilio and Google. When you choose to enable a
            feature, and where required to by the third party, we share whatever
            information is necessary for the feature to function. We may use
            certain information about you without identifying you as an
            individual to third parties. We do this for purposes such as
            analyzing how the Qasa Services are used, marketed and improved,
            diagnosing service or technical problems, maintaining security, and
            personalizing content. It is important to keep in mind that we are
            also obliged to comply with applicable laws and legal process.
          </p>
        </section>

        <section>
          <h2 className="section-title">
            How Long We Hold On To Your Data For
          </h2>
          <p className="section-text">
            Qasa needs your personal information in order to provide you with
            our Services, to maintain our business relationship with you, and in
            certain cases to be in compliance with applicable laws and legal
            process. When it is no longer needed, we will delete your
            information after 5 years, or as soon as possible after you ask us
            to do so unless required to retain this information by applicable
            laws or other legal requirements. Information may persist in copies
            made for backup and disaster continuity purposes for an additional
            amount of time.
          </p>
        </section>

        <section>
          <h2 className="section-title">How Can I Delete My Data?</h2>
          <p className="section-text">
            <ol className="deletion-steps">
              <li>Open the Qasa app</li>
              <li>
                Go to your profile page by tapping on your user profile image on
                the top left of the app screen.
              </li>
              <li>Click on "Support".</li>
              <li>On the next screen, click on "Delete Account".</li>
              <li>
                You will be asked a prompt that says, "Are you sure you want to
                delete?"
              </li>
              <li>Click "Confirm".</li>
              <li>
                On the next screen, enter the OTP that will be sent to your
                number.
              </li>
              <li>
                Once OTP is filled, it will automatically advance to the next
                screen, for a final confirmation of your action.
              </li>
              <li>
                This screen has a prompt that asks, "Are you sure you want to
                delete your account?" Click on one of the two buttons, "Confirm"
                or "Cancel," to either confirm or cancel your account deletion
                action.
              </li>
            </ol>
            For further enquiries on account deletion, please send all request
            or complaints to our support email.{" "}
            <a href="mailto:support@qasa.me">support@qasa.me</a>
          </p>
        </section>

        <section>
          <h2 className="section-title">Your Rights</h2>
          <p className="section-text">
            You can see, review and change most of your personal information by
            signing in to your BackOffice account. Please update your personal
            information immediately if it changes or is inaccurate. If you
            encounter any issues managing your personal data, our Care Team will
            assist you.
          </p>
        </section>

        <section>
          <h2 className="section-title">
            Changes And Updates To This Privacy Policy
          </h2>
          <p className="section-text">
            If our information practices change in any significant way, we will
            post the policy changes here. Changes to the Privacy Policy will
            become effective when they are posted on this page. This Privacy
            Policy was last updated on September 14th, 2023.
          </p>
        </section>
      </main>

      <MicroFooter />
    </>
  );
};

export default MicroPrivacyPolicy;
