import moment from "moment-timezone";
import { ALL_TIMEZONES } from "./constants";

export function getUploadablePartOfB64(imageData: string) {
    if (imageData && isBase64String(imageData)) return imageData.split(",")[1];
    else return null;
}

// @ts-ignore
export function isBase64String(data: string | null | undefined) {
    if (data) return data.substr(0, 5) === "data:";
    else return false;
}

export function isNumber(value: any): boolean {
    // Convert the value to a number using parseFloat
    const number = parseFloat(value);
    
    // Check if the result is a number and not NaN
    return !isNaN(number) && isFinite(number);
}

interface Currency {
    alphabetic_currency_code: string;
    country_code: string;
    country_name: string;
    currency_name: string;
    currency_symbol: string;
    numeric_currency_code: number;
    sub_units: string;
    us_dollar_exchange_rate?: number | null;
    euro_exchange_rate?: number | null;
    exchange_rate_date?: string | null;
    fixed_rate?: number | null;
  }
  
  export function sortCurrenciesByValue(currencies: Currency[]): Currency[] {
    // Define the relative value ranking (1 is highest)
    const currencyValueRanking: { [key: string]: number } = {
      GBP: 1, // Pound Sterling
      EUR: 2, // Euro
      USD: 3, // United States Dollar
      CAD: 4, // Canadian Dollar
      ZAR: 5, // South African Rand
      EGP: 6, // Egyptian Pound
      GHS: 7, // Ghanaian Cedi
      NGN: 8  // Nigerian Naira
    };
  
    // Sort currencies based on the predefined value ranking
    return currencies.sort((a: Currency, b: Currency) => {
      const rankA = currencyValueRanking[a.alphabetic_currency_code] || 999;
      const rankB = currencyValueRanking[b.alphabetic_currency_code] || 999;
      return rankA - rankB;
    });
  }

  export function sortTickets(tickets: Array<any>) {
    if (!tickets) {
      return [];
    } else {
      return tickets.sort((a: any, b: any) => {
        const rankA = a?.status === "PURCHASED" ? 1 : 0;
        const rankB = b?.status === "PURCHASED" ? 1 : 0;
        
        // If one is purchased and the other is not, move "PURCHASED" ticket to the top
        return rankB - rankA;
      });
    }
  }
  

  export const extractDateOrTime = (
    event: {event_date: string, event_timezone: string},
    format: 'date' | 'time'
) => {
    const { event_timezone, event_date } = event;

    // If only time is requested.
    if (format === 'time') {
        const date = new Date(event_date);
        const options: Intl.DateTimeFormatOptions = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        };

        try {
            const timeString = date.toLocaleTimeString('en-US', options);
            return event_timezone ? `${timeString} (${event_timezone})` : timeString;
        } catch (error) {
            console.error('Error formatting time:', error);
            return '';
        }
    }

    // If only date is requested, format it as "FRIDAY, 29 MAR 2024".
    if (format === 'date') {
        const date = new Date(event_date);
        const daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
        const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

        const dayName = daysOfWeek[date.getDay()];
        const dayNumber = date.getDate();
        const monthName = monthNames[date.getMonth()];
        const yearNumber = date.getFullYear();

        return `${dayName}, ${dayNumber < 10 ? '0' : ''}${dayNumber} ${monthName} ${yearNumber}`;
    }

    return '';
};


interface DateTimeResult {
  date: string;
  time: string;
}
function formatDate(inputDate: string) {
  const date = new Date(inputDate);

  // Get the year, month, day, hours, and minutes
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  // Return the formatted string
  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

export const convertDateTimeToUsersTimezone = (
  dateTime: string | null | undefined,
  timeZone: string | null | undefined
): DateTimeResult => {
  // Handle invalid or missing inputs
  if (!dateTime || !timeZone) {
    return {
      // date: "N/A",
      // time: "N/A",
      date: extractDateOrTime(
        { event_date: dateTime ?? "", event_timezone: timeZone ?? "" },
        "date"
      ),
      time: extractDateOrTime(
        { event_date: dateTime ?? "", event_timezone: timeZone ?? ""},
        "time"
      ),
    };
  }

  try {
    const timeZoneToUseToParse: string = timeZone.includes(",") 
    ? timeZone.replaceAll(', ', "/") 
    : timeZone.includes("+") || timeZone.includes("-")
        ? ALL_TIMEZONES.find((x)=>x.includes(timeZone.replace(/^.*([+-].*)$/, "$1"))) ?? timeZone
        : timeZone;

    const eventTime = moment.tz(formatDate(dateTime), timeZoneToUseToParse);

    // Check if the date is valid
    if (!eventTime.isValid()) {
      return {
        date: extractDateOrTime(
          { event_date: dateTime, event_timezone: timeZone },
          "date"
        ),
        time: extractDateOrTime(
          { event_date: dateTime, event_timezone: timeZone },
          "time"
        ),
      };
    }

    // Guess the user's current timezone
    const userTimeZone = moment.tz.guess();

    // Convert the event time to the user's timezone
    const userTime = eventTime.clone().tz(userTimeZone);

    // Format the date and time
    const formattedDate = userTime.format("dddd, DD MMM YYYY").toUpperCase();
    // const formattedTime = `${userTime.format("HH:mm")} (${userTimeZone} ${userTime.format("Z")})`;
    const formattedTime = `${userTime.format("hh:mm A")} (${userTimeZone})`;

    return {
      date: formattedDate,
      time: formattedTime,
    };
  } catch (error) {
    // In case of unexpected errors, return the original inputs
    return {
      date: extractDateOrTime(
        { event_date: dateTime, event_timezone: timeZone },
        "date"
      ),
      time: extractDateOrTime(
        { event_date: dateTime, event_timezone: timeZone },
        "time"
      ),
    };
  }
};

  
  