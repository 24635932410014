import React from "react";
import Lottie from "lottie-react";
import "./css/contact.css";
import MicroFooter from "./MicroFooter";
import MicroNavbar from "./MicroHeader";

const MicroContactPage = () => {
  const [animationData, setAnimationData] = React.useState(null);

  React.useEffect(() => {
    fetch(
      "https://lottie.host/a140b752-5107-480a-b6b2-387ba85a7d98/WZPeDNWyVR.json"
    )
      .then((response) => response.json())
      .then((data) => setAnimationData(data));
  }, []);

  return (
    <div className="page-wrapper">
      <div className="nav-wrapper">
        <MicroNavbar />
      </div>
      <div className="support-container">
        <div className="help-section">
          <div className="help-content">
            <div className="help-info">
              <div className="help-card">
                <h1>Need Assistance?</h1>
                <p>
                  Our support team is ready to assist you. Contact us through
                  these channels, and we'll address your issues and concerns.
                  <br />
                  Additionally, we have curated a list of helpful videos to get
                  you up to speed with the Qasa platform
                </p>
                <button
                  onClick={() =>
                    document
                      .getElementById("videos_help")
                      ?.scrollIntoView({ behavior: "smooth" })
                  }
                  className="help-link"
                >
                  View Help Videos
                </button>
              </div>
            </div>
            <div className="animation-wrapper">
              {animationData && (
                <Lottie
                  animationData={animationData}
                  loop={true}
                  autoplay={true}
                  className="lottie-player"
                />
              )}
            </div>
          </div>
        </div>

        <div className="videos-section" id="videos_help">
          <div className="video-card">
            <div className="video-container">
              <iframe
                src="https://www.youtube.com/embed/Tf83rnoqGgc?si=K4hADci8YKaQo1jf"
                title="How to Monitor Your Sales on Qasa"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </div>
            <div className="video-details">
              <h5>How to Monitor Your Sales on Qasa</h5>
              <p>
                Discover how to effectively track your sales on the Qasa platform.
              </p>
            </div>
          </div>

          <div className="video-card">
            <div className="video-container">
              <iframe
                src="https://www.youtube.com/embed/2cwYtb4p1gg?si=s_U6H5vA0ogWiy56"
                title="How to Scan Tickets"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </div>
            <div className="video-details">
              <h5>How to Scan Tickets</h5>
              <p>
                Wondering how to check people in for your event? This video gives
                a comprehensive walkthrough of how to do it.
              </p>
            </div>
          </div>

          <div className="video-card">
            <div className="video-container">
              <iframe
                src="https://www.youtube.com/embed/EwE0qh0Cnis?si=vtqBVAdQlhWbxp0h"
                title="How to Withdraw Funds from Qasa"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </div>
            <div className="video-details">
              <h5>How to Withdraw Funds from Qasa</h5>
              <p>
                Learn how to withdraw funds from your Qasa account efficiently and
                securely.
              </p>
            </div>
          </div>
        </div>

        <div className="social-header">
          <h3>Reach us on socials!</h3>
        </div>

        <div className="social-grid">
          <a href="https://www.instagram.com/qasa_app" className="social-link">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
              <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
            </svg>
            <span>Instagram</span>
          </a>

          <a href="https://twitter.com/qasa_app" className="social-link">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
              <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
            </svg>
            <span>Twitter (X)</span>
          </a>

          <a href="mailto:support@qasa.me" className="social-link">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
              <path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2H2Zm-2 9.8V4.698l5.803 3.546L0 11.801Zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 9.671V4.697l-5.803 3.546.338.208A4.482 4.482 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671Z" />
              <path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034v.21Zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791Z" />
            </svg>
            <span>Email us</span>
          </a>
        </div>
      </div>
      <div className="footer-wrapper">
        <MicroFooter />
      </div>
    </div>
  );
};

export default MicroContactPage;